import React from 'react';
import { NavigationContext } from '../../store/NavigationContext'
import moment from 'moment-timezone';
import { BookContext } from '../../store/BookContext'
import { CustomerContext } from '../../store/CustomerContext'
import { LanguageContext } from '../../store/LanguageContext';

import "react-datepicker/dist/react-datepicker.css";
import styles from './InfoForm.module.css';

import IBAN from 'iban'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { GetTranslateText } from '../../utils/translate';
import { FormattedMessage, useIntl } from 'react-intl';
import { getFingerPrint } from '../../utils/fingerprint';

export default function InfoForm() {
    const intl=useIntl()
    const franceTrad = intl.formatMessage({ id: "france",  defaultMessage:"France"});
    const belgiumTrad= intl.formatMessage({ id: "belgique",  defaultMessage:"Belgique"});
    const spainTrad= intl.formatMessage({ id: "espagne",  defaultMessage:"Espagne"});

    const PasseportTrad = intl.formatMessage({ id: "passport",  defaultMessage:"Passeport"});
    const idCardTrad= intl.formatMessage({ id: "idCArd",  defaultMessage:"Carte d'identité"});
    const drivingLicenseTrad= intl.formatMessage({ id: "drivingLicense",  defaultMessage:"Permis de conduire"});
    const {languageState} = React.useContext(LanguageContext)
    moment.tz.setDefault("Europe/Paris");
    const { navigationDispatch } = React.useContext(NavigationContext);
    const { customerState, customerDispatch } = React.useContext(CustomerContext);
    const { bookState, bookDispatch } = React.useContext(BookContext);
    const [ error, setError ] = React.useState(null);
    const [ step, setStep ] = React.useState(1);
    const [ query, setQuery ] = React.useState('');
    const [disableBtn, setDisableBtn] = React.useState(false)
    const [ customer, setCustomer ] = React.useState({
        email: customerState.customer.email._cdata,
        birthday: customerState.customer.birthday?customerState.customer.birthday._cdata:null,
        prestashopId: customerState.customer.id._cdata,
        firstName: customerState.customer.firstname._cdata,
        lastName: customerState.customer.lastname._cdata,
        address1: customerState.customer.address?customerState.customer.address.address1._cdata:null,
        address2: customerState.customer.address?customerState.customer.address.address2._cdata:null,
        postCode: customerState.customer.address?customerState.customer.address.postcode._cdata:null,
        city: customerState.customer.address?customerState.customer.address.city._cdata:null,
        country: customerState.customer.informations?customerState.customer.informations.country:'France',
        personalIdType: customerState.customer.informations?customerState.customer.informations.personalIdType:null,
        idNumber:  customerState.customer.informations?customerState.customer.informations.idNumber:null,
        personalIdDeliveryDate:  customerState.customer.informations?moment( customerState.customer.informations.personalIdDeliveryDate).format('yyyy-MM-DD'):null,
        idLocation:  customerState.customer.informations?customerState.customer.informations.idLocation:null,
        iban:  customerState.customer.informations?customerState.customer.informations.iban:null,
        phone: customerState.customer.informations?customerState.customer.informations.phone:null,
        bic: '',
        origin: "web"
    });
    const alertTradIncorrectFormat =  intl.formatMessage({ id: "alertTradIncorrectFormat",  defaultMessage:"Format incorrect."});

    const desktopDate = (date) =>{
        var dateTest = moment(date, 'YYYY-MM-DD', true).isValid()
        // console.log("dateTest",dateTest, date)
            if(dateTest){
                setCustomer({...customer, personalIdDeliveryDate: date})
            }else{
                setCustomer({...customer, personalIdDeliveryDate: null})
            }
    }

    React.useEffect(() => {
        const SaveRefurbInfos = async (customerData) =>{
        fetch(`${process.env.REACT_APP_ADMIN_URL}/apiFront/refurb/save-v2`, {
            method: 'POST',
             headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`
            },
            body: JSON.stringify({
              customer: customerData,
              books: bookState.cart,
              idCart: bookState.idCart,
              device_id: await getFingerPrint(),
              mondialRelayId: 'AUTO',
              origin: 'web',
              lang: languageState
            })
        })
        .then(res => res.json())  
        .then(res => {
            // console.log("res Refurb save front",res)
            setDisableBtn(false)

            if (!res || res.error) {
                if(res.message){
                    setError(res.message)
                }
                if(res.errorMail){
                    navigationDispatch({type:'print'})
                }
            }else{
              bookDispatch({type:'addExpNb', payload:res.data.mondialRelayExpNb})
              bookDispatch({type:'addRefurbId', payload:res.data._id})

              navigationDispatch({type:'print'})
            }

        }).catch(function(err) {
            console.log(err);
        });
    }
        const SaveCustomerInfos = () =>
            fetch(`${process.env.REACT_APP_ADMIN_URL}/apiFront/customer/save/`, {
                method: 'POST',
                 headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`
                },
                body: JSON.stringify( query )
            })
            .then(res => res.json())  
            .then(res => {
                // console.log("customer/save response", res)
                if (!res || res.error !== 0) {
                    setError('Erreur lors de la sauvegarde de vos informations')
                }else{
                    customerDispatch({type:'savedCustomer', payload: res.data})
                    SaveRefurbInfos(res.data)
                }
            }).catch(function(err) {
                console.log(err);
            });
        if (query !== '') {
            SaveCustomerInfos();
            setQuery('')
        }
    }, [query, navigationDispatch, customerDispatch, bookDispatch, bookState.cart, customerState.savedCustomer, languageState, bookState.idCart])

  return (
    <>
    {step === 1 ?
      <>
    <div className={styles.form}>
       

      <form
            className={styles.infoForm}
            id='form-step1'
            onSubmit={e => {
                e.preventDefault();
                setStep(2)
                
        }}>
            <h3 className={styles.title}>
                <FormattedMessage
                    id='infoForm.title'
                    defaultMessage="Mes infos"
                />
            </h3>
            <div className="form-group row noMarginHorizontal">
                <label className="col-4 col-form-label noPadding">
                    {GetTranslateText(languageState, "accountCreationForm.firstname")}*
                </label>
                <div className="col-8">
                    <input type="text" className={"form-control " + styles.input}
                        onChange={e => setCustomer({...customer, firstName: e.target.value}) }
                        value={customer.firstName || ''}
                        required
                        />                
                </div>
            </div>
            <div className="form-group row noMarginHorizontal">
                <label className="col-4 col-form-label noPadding">
                {GetTranslateText(languageState,"accountCreationForm.lastname")}*
                </label>
                <div className="col-8">
                    <input type="text" className={"form-control " + styles.input}
                        onChange={e => setCustomer({...customer, lastName: e.target.value}) }
                        value={customer.lastName || ''}
                        required
                        />               
                </div>
            </div>
            <div className="form-group row noMarginHorizontal">
                <label className="col-4 col-form-label noPadding">
                    <FormattedMessage
                        id="infoForm.adr"
                        defaultMessage=" Adresse"
                    /> 1
                </label>
                <div className="col-8">
                    <input type="text" className={"form-control " + styles.input}
                        onChange={e => setCustomer({...customer, address1: e.target.value}) }
                        value={customer.address1 || ''}
                        required
                        />                
                </div>
            </div>
            <div className="form-group row noMarginHorizontal">
                <label className="col-4 col-form-label noPadding">{GetTranslateText(languageState,"infoForm.adr")} 2</label>
                <div className="col-8">
                    <input type="text" className={"form-control " + styles.input}
                        onChange={e => setCustomer({...customer, address2: e.target.value}) }
                        value={customer.address2 || ''}
                        
                        />                
                </div>
            </div>
            <div className="form-group row noMarginHorizontal">
                <label className="col-4 col-form-label noPadding">
                    <FormattedMessage
                        id="infoForm.PostalCode"
                        defaultMessage="Code postal"
                    />
                </label>
                <div className="col-8">
                    <input type="text" 
                        className={"form-control " + styles.input}
                        pattern="^[0-9]+$"
                        onChange={e => {
                            e.target.setCustomValidity('')
                            setCustomer({...customer, postCode: e.target.value}) }}
                        value={customer.postCode || ''}
                        required
                        onInvalid={event=> {
                            if(event.target.validity.patternMismatch){
                                event.target.setCustomValidity('Le code postal ne doit contenir que des chiffres.');
                            }else{
                                event.target.setCustomValidity('');
                            }
                        }}
                        />                
                </div>
            </div>
            <div className="form-group row noMarginHorizontal">
                <label className="col-4 col-form-label noPadding">
                    <FormattedMessage
                        id="infoForm.city"
                        defaultMessage="Ville"
                    />* 
                </label>
                <div className="col-8">
                    <input type="text" className={"form-control " + styles.input}
                        onChange={e => setCustomer({...customer, city: e.target.value}) }
                        value={customer.city || ''}
                        required
                        />                
                </div>
            </div>
            <div className="form-group row noMarginHorizontal">
                <label className="col-4 col-form-label noPadding">
                    <FormattedMessage
                        id="infoForm.country"
                        defaultMessage="Pays"
                    />
                </label>
                <div className="col-8">
                    <select className={"form-control " + styles.input}
                            value={ customer.country || ""}
                            onChange={e => setCustomer({...customer, country: e.target.value})} 
                            required   
                        >
                        <option value='' disabled></option>
                        <option value="France">{franceTrad}</option>
                        <option value='Belgique'>{belgiumTrad}</option>
                        <option value='Espagne'>{spainTrad}</option>

                    </select>          
                </div>
             </div>

             <div className="form-group row noMarginHorizontal">
                <label className="col-4 col-form-label noPadding">
                    <FormattedMessage id='infoForm.phoneNumber' defaultMessage='Numéro de tépléphone'/>
                </label>
                <div className="col-8">
                    <input type="text" 
                        className={"form-control " + styles.input}
                        pattern="^[0-9+]+$"
                        onChange={e => {
                            e.target.setCustomValidity('')
                            setCustomer({...customer, phone: e.target.value}) }}
                        value={customer.phone || ''}
                        required
                        onInvalid={event=> {
                            if(event.target.validity.patternMismatch){
                                event.target.setCustomValidity(alertTradIncorrectFormat);
                            }else{
                                event.target.setCustomValidity('');
                            }
                        }}
                        />                
                </div>
            </div>
            <h3 className={styles.title}>
                <FormattedMessage
                    id="infoForm.IdProof"
                    defaultMessage="Preuve d'identité"
                />
            </h3>
            <div className={styles.conditionSentence}>
                <FormattedMessage
                    id="infoForm.IdProofLaw"
                    defaultMessage="La Loi française nous oblige à vous demander une pièce d'identité pour revendre des livres, afin de lutter contre le recel. Merci de préciser le numéro de votre carte d'identité ou de votre passeport."
                />  
            </div>
            <div 
                className={styles.dataUsage}
                onClick={() => navigationDispatch({ type: 'displayCGAU'})}
              >
                <FormattedMessage
                    id="infoForm.personalData"
                    defaultMessage="En savoir plus sur la manière dont nous traitons vos données"
                />
            </div>
            <br></br>
              <div className="form-group row noMarginHorizontal">
                <label className="col-4 col-form-label">
                    <FormattedMessage
                        id="infoForm.type"
                        defaultMessage="Type"
                    />
                </label>
                <div className="col-8">
                    
                    <select className={"form-control " + styles.input}
                            value={ customer.personalIdType || ''}
                            onChange={e => setCustomer({...customer, personalIdType: e.target.value})} 
                            required   
                        >
                        <option value='' disabled></option>
                        <option value='Passport'>{PasseportTrad}</option>
                        <option value="Carte d'identité">{idCardTrad}</option>
                        <option value="Permis de conduire">{drivingLicenseTrad}</option>

                    </select>          
                </div>
             </div>
            <div className="form-group row noMarginHorizontal">
                <label className="col-4 col-form-label">
                    <FormattedMessage
                        id="infoForm.number"
                        defaultMessage="Numéro"
                    />
                </label>
                <div className="col-8">
                    <input type="text" className={"form-control " + styles.input}
                        onChange={e => setCustomer({...customer, idNumber: e.target.value}) }
                        value={customer.idNumber || ''}
                        required
                        />               
                </div>
            </div>
            <div className="form-group row noMarginHorizontal">
                <label className="col-4 col-form-label">
                    <FormattedMessage
                        id="infoForm.dateOfDelivery"
                        defaultMessage="Date de délivrance"
                    />
                </label>
                <div className="col-8">
                    <input   className={"form-control " + styles.input}
                        placeholder='exemple: 01/12/1988'
                        onChange={e=>desktopDate(e.target.value)}
                        required
                        type="date"
                        value = { customer.personalIdDeliveryDate || null}
                        // pattern="[0-3^a-b]{1}[0-9]{1}\/[0-1]{1}[0-9]{1}\/[0-9^a-b]{4}"
                    />
                </div>
            </div>
            <div className="form-group row noMarginHorizontal">
                <label className="col-4 col-form-label">   
                    <FormattedMessage
                        id="infoForm.deliveryLocation"
                        defaultMessage="Lieu de délivrance"
                    />
                </label>
                <div className="col-8">
                    <input type="text" className={"form-control " + styles.input}
                        onChange={e => setCustomer({...customer, idLocation: e.target.value}) }
                        value={customer.idLocation || ''}
                        required
                        />               
                </div>
            </div>
            
        </form>

        
        {error ?
            <span className={"alert alert-danger " + styles.error} role="alert">
                {error}
            </span>
        :null}
     
    </div>

    <div className={styles.buttonRow}>
        <div className={styles.buttonDiv}>
        <button 
            className={'btn btn-grey '}
            onClick={ () => navigationDispatch({type:'displayCart'})}
        ><i className="far fa-arrow-alt-circle-left"></i>
            {GetTranslateText(languageState,'btn.backMin')}
        </button>
        </div>
        
        <div className={styles.buttonDiv}>
        <button 
            className={'btn btn-orange'}
            form='form-step1'
            type='submit'
        >
            <FormattedMessage 
                id="btn.nextMin"
                defaultMessage="Suite"
            />
            <i className="far fa-arrow-alt-circle-right"></i></button>
        </div>

      </div>
    
    </>

    :step === 2 ?

    <>
        <div className={styles.form}>
            <form
                className={styles.infoForm}
                id='form-step2'
                onSubmit={e => {
                    setDisableBtn(true)
                    e.preventDefault();
                            
                    if(IBAN.isValid(customer.iban)){
                        setError(null)
                        setQuery(customer)
                    }else{
                        setError('iban invalide')
                        setDisableBtn(false)

                    }
                }}
            >
                <h3 className={styles.title}>
                    <FormattedMessage
                        id="infoForm.infoBank"
                        defaultMessage="Mes infos bancaires"
                    />
                </h3>
                <div className={styles.conditionSentence}>
                    <FormattedMessage
                        id="infoForm.bankUsage"
                        defaultMessage="Nous vous payons par virement bancaire."
                    />
                    <br></br>
                    <FormattedMessage
                        id="infoForm.bankUsage2"
                        defaultMessage="Merci de renseigner votre numéro IBAN que vous trouvez sur votre RIB (banque domiciliée dans l'UE). En France, votre IBAN contient 27 caractères."
                    />
                </div>
                <div className="form-group">
                    <label className="col-form-label">
                        <FormattedMessage
                            id="infoForm.iban"
                            defaultMessage="IBAN"
                        />
                    </label>
                    <div>
                        <input type="text" className={"form-control " + styles.input}
                            onChange={e => setCustomer({...customer, iban: e.target.value}) }
                            value={customer.iban || ''}
                            required
                            placeholder="Exemple : FR26 1234 7890 4567 2345 3456 345"
                            />                
                    </div>
                </div>
            </form>
        {error?
            <span className={"alert alert-danger " + styles.error} role="alert">
                {error}
            </span>
        :null}
     
    </div>
    
    <div className={styles.buttonRow}>
        <div className={styles.buttonDiv}>
        <button 
            className={'btn btn-grey '}
            onClick={ () => setStep(1)}
        ><i className="far fa-arrow-alt-circle-left"></i>
        {GetTranslateText(languageState,'btn.backMin')}
        </button>
        </div>
        
        <div className={styles.buttonDiv}>
        {!disableBtn?
        <button 
            className={'btn btn-orange flexH'}
            form='form-step2'
            type='submit'
            disabled={disableBtn}
        >
            {GetTranslateText(languageState,'btn.nextMin')}
         
                <i className="far fa-arrow-alt-circle-right"></i>
        </button>
        :
            <Loader
                type="Puff"
                color="#114970"
                height={30}
                width={30}
                // timeout={3000} //3 secs
            />
        }
  
        </div>

    </div>


</>
:null}
</>
  );
}
