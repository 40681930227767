import React from 'react';

import ScanPage from '../../pages/ScanPage/ScanPage'
import HomePage from '../../pages/HomePage/HomePage'
import CartPage from '../../pages/CartPage/CartPage'
import LoginPage from '../../pages/LoginPage/LoginPage'
import CheckoutPage from '../../pages/CheckoutPage/CheckoutPage'
import EndProcessPage from '../../pages/EndProcessPage/EndProcessPage'
import CgauPage from '../../pages/CGAU/CgauPage'
import RefurbConditionPage from '../../pages/RefurbConditionPage/RefurbConditionPage';
import AccountCreatePage from '../../pages/createAccountPage/createAccountPage'
import Footer from '../../components/Footer/Footer'
import IntroPage from '../../pages/IntroPage/IntroPage'
import styles from './Layout.module.css'
//store
import { NavigationContext } from '../../store/NavigationContext' 

function Layout() {

  const { navigationState } = React.useContext(NavigationContext)

  return (
    <div className={styles.layoutContainer} >
    {navigationState.displayIntroPage?<IntroPage/>:null}
    {navigationState.displayHomePage?<HomePage/>:null}
    {/* {navigationState.displayScanPage && bookState.tooHeavy?< ScanPage/>:<CartPage/>} */}
      <ScanPage/>
      <CartPage/>
        
      <LoginPage/>
      <AccountCreatePage/>
      <CheckoutPage/>
      <EndProcessPage/>
      <CgauPage></CgauPage>
      <RefurbConditionPage/>
      {!navigationState.displayIntroPage?<Footer></Footer>:null}
    </div>
  );
}

export default Layout;
