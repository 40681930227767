import React from 'react';
import {IntlProvider} from "react-intl";

import fr from '../assets/translations/fr.json'
import es from '../assets/translations/es.json'

const tradList = { fr, es };

const LanguageContext = React.createContext();

  const initialState = 'fr'


// it provides the language context to app

function languageReducer(state, action){
    // console.log("state,action",state,action)
    switch (action.type) {
        case 'init':{
            return action.payload
        }
        default:{
          return state
        }
    }
}
function LanguageProvider({ children }) {
    const [languageState, languageDispatch] = React.useReducer(languageReducer, initialState);
    React.useEffect(() => {
      localStorage.setItem('lang', JSON.stringify(languageState))
    }, [languageState])
    return (
      <LanguageContext.Provider value={{languageState, languageDispatch}}>
        <IntlProvider locale={languageState} messages={tradList[languageState]}>
            {children}
        </IntlProvider>
      </LanguageContext.Provider>
    );
  };

  export { LanguageProvider, LanguageContext }