import React from 'react';
import { NavigationContext } from '../../store/NavigationContext' 
import { BookContext } from '../../store/BookContext'
import { LanguageContext } from '../../store/LanguageContext';
import styles from './LoginPage.module.css'

import LogoHeader from '../../components/Headers/LogoHeader/LogoHeader'
import LoginForm from '../../components/LoginForm/LoginForm'
import InfoForm from '../../components/InfoForm/InfoForm'
import { FormattedMessage ,useIntl } from 'react-intl';
import { GetTranslateText } from '../../utils/translate'

export default function LoginPage() {
    const intl=useIntl()
    const { bookState } = React.useContext(BookContext)
    const { navigationState } = React.useContext(NavigationContext)
    const { languageState } = React.useContext(LanguageContext)
    const articleTrad = intl.formatMessage({ id: "article",  defaultMessage:"article"});
    const articlesTrad = intl.formatMessage({ id: "articles",  defaultMessage:"articles"});

    let filteredCart = bookState.cart.filter(book => book.userChoice === 'sold')
    const totalPrice = filteredCart.reduce((count, curItem) => count + curItem.price, 0)

    
    if (navigationState.displayLoginPage){
        return(
            <div className={styles.container}>
            <LogoHeader/>
            <div className={styles.content}>
                <h1 className={styles.title}>
                  < FormattedMessage 
                    id="cartRefurb"
                    defaultMessage="Panier reprise"
                  />
                </h1>
              <div className={styles.cartSumUp + ' ph30'}>
                <span>{filteredCart.length} {filteredCart.length>1?articlesTrad:articleTrad}</span>
                <span className={styles.totalPrice}>{totalPrice.toFixed(2).toString().replace('.', ',')} <sup>€</sup></span>
              </div>
    
              
              <LoginForm/>
              
            </div>
        </div>
        )
    }else if(navigationState.displayLoggedinPage){
        return(
            <div className={styles.container}>
            <LogoHeader/>
            <div className={styles.content}>
                <h1 className={styles.title}>
                  {GetTranslateText(languageState,'cartRefurb')}
                </h1>
              <div className={styles.cartSumUp + ' ph30'}>
                <span>
                  {filteredCart.length} 
                  {articlesTrad}
                </span>
                <span className={styles.totalPrice}>{totalPrice.toFixed(2).toString().replace('.', ',')} <sup>€</sup></span>
              </div>
      
             
              <InfoForm/>  
              
            </div>
          </div>
        )
    }else{
        return <></>
    }
}

