import React from 'react';
import styles from './Footer.module.css';
import { NavigationContext } from '../../store/NavigationContext' 

import FeatherIcon from 'feather-icons-react';

import { FormattedMessage } from 'react-intl';

export default function Footer(){
    const { navigationDispatch } = React.useContext(NavigationContext)

  return (
    <div className={styles.footer}>
        <FeatherIcon 
          onClick={()=>navigationDispatch({type: "displayIntro", payload: true})}
          color='white' icon='help-circle'
        />
        <span style={{"margin":"0 10px", color:'white', fontSize:".70em"}} 
            onClick={() => navigationDispatch({ type: 'displayCGAU'})}
        >
        < FormattedMessage 
            id="footer.ourCgau"
            defaultMessage="Nos CGAU"
          />
        </span>
    </div>
  );
}
