import React from 'react';
import { NavigationContext } from '../../store/NavigationContext' 
import styles from './CartPage.module.css'
import { BookContext } from '../../store/BookContext'
import { LanguageContext } from '../../store/LanguageContext';

import alertTriangle from '../../assets/images/alert-triangle-yolk.svg'

import LogoHeader from '../../components/Headers/LogoHeader/LogoHeader'
//utils
import { fetchEventsUpdate } from '../../utils/events.js';
//Trad
import { FormattedMessage } from 'react-intl';
import { GetTranslateText } from '../../utils/translate';

export default function CartPage() {
    const { bookState, bookDispatch } = React.useContext(BookContext)
    const { navigationState, navigationDispatch } = React.useContext(NavigationContext)
    const [disabled, setDisabled] = React.useState(true) 
    const [tooHeavy, setTooHeavy] = React.useState(false) 
    const { languageState } = React.useContext(LanguageContext)
    const [filteredCart, setFilteredCart] = React.useState([]) 

    let totalPrice = filteredCart !== 0 ?filteredCart.reduce((count, curItem) => count + curItem.price, 0.00):0.00

    let totalWeight = filteredCart !== 0 ?filteredCart.reduce((count, curItem) => count + curItem.weight, 0.00):0.00

    const minimumPriceNeeded = (process.env.REACT_APP_PRICE - totalPrice).toFixed(2);
    var pricePartner = (Math.round(totalPrice * 0.2 * 100)/100).toFixed(2);

    React.useEffect(()=>{
      setDisabled(  !(totalPrice >= process.env.REACT_APP_PRICE) )
      setTooHeavy(totalWeight >= process.env.REACT_APP_WEIGHT_THRESHOLD)
      setFilteredCart(bookState.cart.filter(book => book.userChoice === 'sold'));
    },[bookState,minimumPriceNeeded, totalWeight, totalPrice])

    React.useEffect(()=>{
        bookDispatch({type: 'tooHeavy', payload: tooHeavy});
    },[tooHeavy, bookDispatch])
 

    const finalize = ()=>{
        // console.log("customerState finalize",customerState);
        navigationDispatch({type:'displayLogin'})
        // if(!customerState.customer){
        //   navigationDispatch({type:'displayLogin'})
        // }else{
        //   navigationDispatch({type:'customerLoggedIn'})
        // }
    }
  return (
    <>
    {navigationState.displayCartPage ?
      <div className={styles.container}>
        <LogoHeader/>
        <div className={styles.content}>
          <h1 className={styles.title}>
            {GetTranslateText(languageState,'cartRefurb')}
          </h1>
          <div className={styles.boxTopBtn}>
          <div className={styles.articles + ' ph30'}>
          {filteredCart.length}  {filteredCart.length>1?GetTranslateText(languageState,'articles'):GetTranslateText(languageState,'article')} 
          </div>
      

          {filteredCart.length>1?
            <div className={styles.btnTop} >
              <button 
                disabled ={disabled}
                className={'btn btn-orange'} 
                onClick={finalize}
              >
                <i className="far fa-check-circle"></i> 
                {GetTranslateText(languageState,'btn.finalize')}
              </button>
            </div>
            :
            null}
          </div>
          <div className={styles.lineTop}></div>

          <div className={styles.bookBlock + ' ph30'}>

          {filteredCart.map((book, index) => {
            // console.log("book._i",book._id)
              return <div className={styles.bookItem} key={book._id}>
                      <div className={styles.bookImgTitleAuthor}>
                        <img 
                            className={styles.imageResponsive}
                            src={book.image} 
                            alt={book.title}/>
                        <div>
                          <span className={styles.bookTitle}>{book.title}</span>
                          {book.authors ?
                            <p className={styles.authors}><span>de </span>
                                {book.authors.map((author, index) => {
                                    return index + 1 < book.authors.length ?
                                        <span key={index}>{author.split(', ')[1]} {author.split(', ')[0]} et </span>
                                    :
                                        <span key={index}>{author.split(', ')[1]} {author.split(', ')[0]}</span>
                                })}
                            </p>
                          :null}
                        </div>
                      </div>
                      <div className={styles.actions + ' ph30'}>
                      {/* <i className={"far fa-eye " + styles.eyeLogo}
                        alt='logo'
                        // onClick={() => bookDispatch({ type:'deleteBook', payload:book.id })}
                        >
                        </i> */}
                        <i 
                          className={'far fa-trash-alt ' + styles.binLogo} 
                          alt='logo'
                          onClick={() =>{
                                const bookDeleted =  bookState.cart.filter(cartItem => cartItem._id === book._id);
                                fetchEventsUpdate("trash",{book : bookDeleted[0]});
                                bookDispatch({ type:'deleteBook', payload: book._id })

                              }
                            }
                          >
                        </i>
                        <span className={styles.bookPrice}>{book.price.toFixed(2).toString().replace('.', ',')} <sup>€</sup> 
                        </span>
                      </div>
                    </div>
                   }
                )
            }
          </div>
          
          { disabled || tooHeavy ?
            <div className={styles.minimumPriceBlock + ' ph30'}>
                <img src={alertTriangle} alt='alert icon'></img>
              {/* <i className={"fas fa-exclamation-triangle " + styles.warningLogo}></i> */}
              {disabled?
                <div className={styles.minimumPriceSentence}>
                  <FormattedMessage 
                    id="cartScreen.controlePrice.1"
                    defaultMessage="Encore"
                    description="controle prix"
                  /> 
                  <span className={styles.minimumPrice}> {minimumPriceNeeded.toString().replace('.', ',')} <sup>€ </sup>
                  </span>
                  <FormattedMessage 
                    id="cartScreen.controlePrice.2"
                    defaultMessage=" pour bénéficier du service"
                    description="controle prix"
                  /> 
                </div>
              :
              null
              }
              {tooHeavy && disabled?
              
                <div className={styles.line}></div>
              :
              null
              }
              {tooHeavy?
                <div className={styles.minimumPriceSentence}>
                   <FormattedMessage 
                    id="cartScreen.controleWeight.1"
                    defaultMessage="Vous avez atteint le poids maximal pour le colis."
                    description="controle poids"
                  /> 
                    <FormattedMessage 
                      id="cartScreen.controleWeight.2"
                      defaultMessage="L'ajout d'un nouveau livre n'est plus possible. "
                      description="controle poids"
                    /> 
                  <br></br>
                  <FormattedMessage 
                    id="cartScreen.controleWeight.3"
                    defaultMessage="Si vous avez d'autres livres à nous revendre, veuillez créer un nouveau panier après avoir finalisé celui-ci."
                    description="controle poids"
                  />
                </div>
              :
              null
              }
            </div>
          
          :
          <div className={styles.line}></div>
          }

            <div className={styles.totalPrice  + ' ph30'}>
                <p className={styles.totalDesc}>
                  <FormattedMessage 
                    id="cartScreen.total"
                    defaultMessage="Total reprise"
                  />
                     <span className={styles.priceCustomer}>{totalPrice.toFixed(2).toString().replace('.', ',')}&nbsp;<sup>€</sup></span>
                </p>
                <p className={styles.infoPrice}>
                    <FormattedMessage 
                      id="checkoutPage.giftPartner1"
                      defaultMessage=" A la revente, nous verserons"
                    />
                    <span className={styles.pricePartner}> {pricePartner.toString().replace('.', ',')} <sup>€</sup></span>
                    <FormattedMessage 
                      id="checkoutPage.giftPartner2"
                      defaultMessage="à nos partenaires caritatifs."
                    />
                </p>
                {/* <span>Total reprise</span> */}
                {/* <span>{totalPrice.toFixed(2).toString().replace('.', ',')} <sup>€</sup></span> */}
            </div>
            <div className={styles.conditionSentence  + ' ph30'}>
                <i className={"fa fa-exclamation-triangle"} style={{paddingRight:5}}></i>
                <FormattedMessage 
                  id="cartScreen.conditionRefurb"
                  defaultMessage="Lisez bien les conditions de reprise. Nous ne prenons que les livres en très bon état et le paiement n’est déclenché que lorsque le livre est accepté."
                />
                {/* sous réserve que votre envoi et son état soit conforme à votre déclaration */}
            </div>

          <div className={styles.buttonRow  + ' ph30'}>
           { !tooHeavy ?
            <button 
                  className={'btn btn-blue'}
                  onClick={ () => navigationDispatch({type:'back'})}
            >
              <i className="far fa-arrow-alt-circle-left"></i>
              {GetTranslateText(languageState,'btn.continue')}
            </button>
            :
            <div></div>
            }

            <button 
              disabled ={disabled}
              className={'btn btn-orange'}
              onClick={finalize}
            >
                <i className="far fa-check-circle"></i> 
                {GetTranslateText(languageState,'btn.finalize')}
              </button>
          </div>
        </div>
      </div>
    :null}
    </>
  );
}
