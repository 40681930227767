const { getFingerPrint } = require('./fingerprint')

function checkStatus(response) {
    // console.log('checkStatus',response)
    if (response.status >= 200 && response.status < 300) {
      return response
    } else {
      var error = new Error(response.status)
      error.response = response
      throw error
    }
  }
  function parseJSON(response) {
    return response.json()
  }

export async function getCart (){
    return new Promise(async (resolve, reject)=>{
        fetch(`${process.env.REACT_APP_ADMIN_URL}/apiFront/refurb/getCart`, {
            method: 'POST',
             headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`
            },
            body: JSON.stringify({
                device_id: await getFingerPrint(),
            })
        })
        .then(checkStatus)
        .then(parseJSON)
        .then(function(data) {
           
            // console.log('request succeeded with JSON response', data);
            resolve({error:0, data})

        })
        .catch(function(error) {
            console.log('request failed ', error)
            resolve({error:1, message:"Impossible de charger le panier. Un redémarrage de votre application est nécessaire."});
        })
    })

}

export async function fetchDeleteCart(){
  let stats = { 
      device_id: await getFingerPrint()
    }
  let returnData
  await fetch(`${process.env.REACT_APP_ADMIN_URL}/apiFront/events/deleteCart`, {
      method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`
        },
        body: JSON.stringify( stats )
  }) 
  .then(checkStatus)
  .then(parseJSON)
  .then(function(data) {
      // console.log('request succeeded with JSON response', data);
      returnData = data
  }).catch(function(error) {
      console.log('request failed', error)
      returnData = {error:1, message:"Une erreur est survenue"};
      
  })
  return returnData
}