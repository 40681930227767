import * as React from 'react';
//lang
import ReactFlagsSelect from "react-flags-select";
//store
import {LanguageContext } from '../../store/LanguageContext'

function LangSelector() {
  const { languageState, languageDispatch } = React.useContext(LanguageContext);
     // set selected language by calling context method
    // console.log("languageState",languageState)
    return(
        <div style={{width:"100%"}}>
            <ReactFlagsSelect
                onSelect={(value)=> {console.log("value cca2",value);languageDispatch({type:'init',payload:value.toLowerCase()});}}
                countries={["FR", "ES"]}
                customLabels={{"FR": "France","ES": "Espagnol"}}
                selected={languageState.toUpperCase() }
                showSelectedLabel={false}
                selectButtonClassName="flags
                "
            />
        </div>
    )

}

export default LangSelector