import React from 'react';

const NavigationContext = React.createContext();
const localState = JSON.parse(localStorage.getItem('navigationState'));

const globalState = {
    displayIntroPage: true,
    displayHomePage: false,
    displayScanPage: false,
    displayWebcam: false,
    displayManual: false,
    displayCartPage: false,
    displayLoginPage: false,
    displayLoggedinPage: false,
    displayCheckout: false,
    displayEndProcess: false,
    displayPrint: false,
    displayCGAU: false,
    displayRefurbCondition: false,
    displayCreateAccountPage: false,
    displayScannerPage: false,
}
const initialState={...globalState, historyState: globalState}

function navigationReducer(state, action){
    switch (action.type) {
        case 'reset': {
            localStorage.removeItem("navigationState")
            
            return initialState
        }
        case 'back': {
            return {
                ...initialState,
                displayHomePage: true,
                displayIntroPage: false
            }
        }
        case 'cgauReturn': {
            return initialState.historyState
        }
        case 'displayIntro': {
            return {
                ...initialState,
                displayHomePage: !action.payload,
                displayIntroPage: action.payload
            }
        }
        case 'displayBarCodeScanner': {
            return {
                ...initialState,
                displayScanPage: true,
                displayWebcam: true,
                displayIntroPage: false
            }
        }
        case 'displayCGAU': {
            if(!state.displayCGAU){
                initialState.historyState = {...state}
            }
            return {
                ...initialState,
                displayCGAU: true,
                displayIntroPage: false,
            }
        }
        case 'displayRefurbCondition': {
            return {
                ...initialState,
                displayRefurbCondition: true,
                displayIntroPage: false,
            }
        }
        case 'displayManual': {
            return {
                ...initialState,
                displayScanPage: true,
                displayManual: true,
                displayIntroPage: false
            }
        }
        case 'displayCart': {
            return {
                ...initialState,
                displayCartPage: true,
                displayIntroPage: false
            }
        }
        case 'displayCreateAccount': {
            return {
                ...initialState,
                displayCreateAccountPage: true,
                displayIntroPage: false
            }
        }
        case 'displayLogin': {
            return {
                ...initialState,
                displayLoginPage: true,
                displayIntroPage: false,
            }
        }
        case 'customerLoggedIn': {
            return {
                ...initialState,
                displayLoggedinPage: true,
                displayIntroPage: false
            }
        }
        case 'customerInfo': {
            return {
                ...initialState,
                displayCheckout: true,
                displayIntroPage: false
            }
        }   
        case 'customerEndProcess': {
            return {
                ...initialState,
                displayEndProcess: true,
                displayIntroPage: false
            }
        }
        case 'print': {
            return {
                ...initialState,
                displayPrint: true,
                displayIntroPage: false
            }
        }
        
        default: {
            return state;
        }
    }
}

function NavigationProvider({children}) {
    const [navigationState, navigationDispatch] = React.useReducer(navigationReducer, localState || initialState)

    React.useEffect(() => {
        localStorage.setItem('navigationState', JSON.stringify(navigationState))
    }, [navigationState])

    return (
      <NavigationContext.Provider value={{navigationState, navigationDispatch}}>
          {children}
      </NavigationContext.Provider>
    )
}

export { NavigationProvider, NavigationContext }