import React, { useEffect } from 'react';
import './App.css';

import { NavigationProvider } from './store/NavigationContext'
import { BookProvider } from './store/BookContext'
import { CustomerProvider } from './store/CustomerContext'
import { LanguageProvider } from './store/LanguageContext';

import Layout from './components/Layout/Layout';
import { useIsNetworkAvailable } from "./utils/networkError";
import FlashMessage from './components/FlashMessage/FlashMessage'

import packageJson from "../package.json";
import moment from "moment";
import { fetchOpenAppEvent } from './utils/events'

function App() {
  let isConnected = useIsNetworkAvailable()
  // const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);
  const refreshCacheAndReload = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }
    // delete browser cache and hard reload
    window.location.reload(true);
  };
  const buildDateGreaterThan = (latestDate, currentDate) => {
    const momLatestDateTime = moment(latestDate);
    const momCurrentDateTime = moment(currentDate);
  
    if (momLatestDateTime.isAfter(momCurrentDateTime)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    fetch("/meta.json")
      .then((response) => response.json())
      .then((meta) => {
        // console.log("meta",meta)
        const latestVersionDate = meta.buildDate;
        const currentVersionDate = packageJson.buildDate;
        // console.log("currentVersionDate",currentVersionDate)
        const shouldForceRefresh = buildDateGreaterThan(
          latestVersionDate,
          currentVersionDate
        );
        if (shouldForceRefresh) {
          console.log("refresh forced")
          // setIsLatestBuildDate(false);
          refreshCacheAndReload();
        }
      });

      //Open app event
      fetchOpenAppEvent()
  }, []);

  
  return (
    <NavigationProvider>
      <LanguageProvider>
        <BookProvider>
          <CustomerProvider>
          {!isConnected?
            <FlashMessage></FlashMessage>:null
          }
              <Layout/>
          </CustomerProvider>
        </BookProvider>
        </LanguageProvider>
     </NavigationProvider>
  );
}

export default App;
