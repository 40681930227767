import React from 'react';

import styles from './HomeCart.module.css'
import { BookContext } from '../../store/BookContext'
import { CustomerContext } from '../../store/CustomerContext'
import { NavigationContext } from '../../store/NavigationContext'
import { FormattedMessage, useIntl } from 'react-intl';
import { GetTranslateText } from '../../utils/translate.js';
import { LanguageContext } from '../../store/LanguageContext';
import { fetchDeleteCart } from '../../utils/getCart';

export default function HomeCart() {
  const { bookState, bookDispatch} = React.useContext(BookContext)
  const { customerState } = React.useContext(CustomerContext)
  const { languageState } = React.useContext(LanguageContext)

  const { navigationDispatch } = React.useContext(NavigationContext);
  const intl = useIntl()
  let filteredCart = bookState.cart.filter(book => book.userChoice === 'sold')
  const totalPrice = filteredCart.reduce((count, curItem) => count + curItem.price, 0)
  const alertTradDelete =  intl.formatMessage({ id: "alertTradDelete",  defaultMessage:"Etes-vous sûr de vouloir supprimer votre panier?"});

  return (
    <div className={styles.container}>
        <h3 className={styles.title}>
          <FormattedMessage 
            id="bottomCart.title"
            defaultMessage="Dans votre panier"
          /> 
        </h3>
        <div className={styles.cart}>
          <div className={styles.articles}>{filteredCart.length>1? `${filteredCart.length} ${GetTranslateText(languageState,'articles')}`:`${filteredCart.length} ${GetTranslateText(languageState,'article')}`}</div>
          <div className={styles.price}>{totalPrice.toFixed(2).toString().replace('.', ',')}<sup>€</sup></div>
        </div>
        <div className={styles.buttons}>
          <button 
            className="btn btn-grey d-flex"
            // onClick={() => navigationDispatch({ type: 'displayWebcam'})}
            onClick={()=>{
              if(window.confirm(alertTradDelete)){
                fetchDeleteCart()
                .then(res =>{
                    console.log("res", res)
                    if(!res.error){
                        bookDispatch({type: "reset"});
                        // props.navigation.navigate('Home')
                    }
                })
              }
            }}
          ><i className="far fa-times-circle"></i>  <FormattedMessage 
              id="bottomCart.btnCancel"
              defaultMessage="Annuler"
            />  </button>
          <button 
            className="btn btn-grey d-flex"
            onClick={() => {
                                if(customerState.savedCustomer){
                                    navigationDispatch({type:'customerInfo'})
                                }else{
                                    navigationDispatch({type:'displayCart'})
                                }
                            }
                        }   
          ><i className="far fa-check-circle"></i>
            <FormattedMessage 
              id="bottomCart.btnCart"
              defaultMessage="panier"
            />  
          </button>
        </div>
    </div>
  );
}
