const { ClientJS } = require('clientjs');

// Create a new ClientJS object
const client = new ClientJS();
// Get the client's fingerprint id
const fingerprint = client.getFingerprint();
  

export async function getFingerPrint(){
  let fingerPrint =  await fingerprint
  return fingerPrint.toString()
}
