import React from 'react';
import styles from './RefurbConditionPage.module.css'
import BackHeader from '../../components/Headers/BackHeader/BackHeader';
import { NavigationContext } from '../../store/NavigationContext'
import FeatherIcon from 'feather-icons-react';
//Trad
import {FormattedMessage} from 'react-intl';


export default function RefurbConditionPage() {
  const { navigationState } = React.useContext(NavigationContext)
  
  return (
    <>
        {
          navigationState.displayRefurbCondition ?
          <div>
            <BackHeader/>
            <div className={styles.content}>  
              <div className={styles.iconBox}>
                <FeatherIcon 
                  className={styles.mR10}
                  color='green' icon='thumbs-up'
                />
                <p className={styles.titleMedium + " " + styles.greenText}>
                  <FormattedMessage 
                    id="RefurbConditionScreen.textOk.1"
                    defaultMessage="Nous reprenons"
                  />
                </p>
              </div>
              <div>
                <p>
                <FormattedMessage 
                  id="RefurbConditionScreen.textOk.2"
                  defaultMessage="Les livres de poche, grand format, bandes dessinées, livres d’art, manuels scolaires et cahiers de vacances (non remplis), ouvrages techniques et universitaires."
                />
                  <br></br>
                  <FormattedMessage 
                    id="RefurbConditionScreen.textOk.3"
                    defaultMessage="Nous reprenons les livres quelle que soit la langue."
                  />
                  <br></br>
                  <FormattedMessage 
                    id="RefurbConditionScreen.textOk.4"
                    defaultMessage="Nous reprenons uniquement les livres en très bon état."
                  />
                  <br></br>
                  <FormattedMessage 
                    id="RefurbConditionScreen.textOk.5"
                    defaultMessage="Les livres doivent avoir un code-barres."
                  />
                </p>
              </div>

              <div className={styles.iconBox}>
                <FeatherIcon 
                  className={styles.mR10}
                  color='#DB1720' icon='slash'
                />
                <p className={styles.titleMedium + " " + styles.redText}>
                  <FormattedMessage 
                    id="RefurbConditionScreen.textko.1"
                    defaultMessage="Nous ne reprenons pas"
                  />
                </p>
              </div>
              <div>
                <p>
                  <FormattedMessage 
                    id="RefurbConditionScreen.textko.2"
                    defaultMessage="Les revues, les encyclopédies, les maisons d’éditions non vendues en librairie."
                  />
                <br></br>
                  <FormattedMessage 
                    id="RefurbConditionScreen.textko.3"
                    defaultMessage=" Les livres abîmés (reliures cassées, livres tâchés, livres annotés…)."
                  />
                <br></br>
                  <FormattedMessage 
                    id="RefurbConditionScreen.textko.4"
                    defaultMessage="Nous ne reprenons pas les livres sans code-barres."
                  />
                </p>
              </div>
            </div>
          </div>
        : 
        null
        }
    </>
  );
}
