import React from 'react';
import axios from 'axios';
import { BookContext } from '../../store/BookContext'
import { CustomerContext } from '../../store/CustomerContext'
import { NavigationContext } from '../../store/NavigationContext'
import styles from './PdfEtiquette.module.css';
import { FormattedMessage } from 'react-intl';

export default function PdfEtiquette() {
    const { bookState, bookDispatch } = React.useContext(BookContext);
    const { navigationDispatch } = React.useContext(NavigationContext);
    const { customerDispatch } = React.useContext(CustomerContext);
    
    function refreshPage(){ 
        window.location.reload(); 
    }
        const fetchPdfMondial = () =>{
            
            axios({
                method: "GET",
                url: `${process.env.REACT_APP_ADMIN_URL}/apiFront/refurb/pdfMondialRelay/` + bookState.mondialRelayExpNb,
                headers: { 'Authorization': `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}` },
                responseType: "blob"
              })
              .then(response => {
                //Create a Blob from the PDF Stream
                var file = new Blob([response.data], {type: 'application/pdf'});
                // setEtiquette(URL.createObjectURL(file));
                openUrl(URL.createObjectURL(file))


                })
                .catch(error => {
                    console.log(error);
                });
    
            
        }
            const fetchPdfRefurb = () =>{
            axios({
                method: "GET",
                url: `${process.env.REACT_APP_ADMIN_URL}/apiFront/refurb/pdfRefurb/` + bookState.refurbId,
                headers: { 'Authorization': `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}` },
                responseType: "blob"
              })
              .then(response => {
                // console.log('pdfRefurb resp file',response)
                //Create a Blob from the PDF Stream
                var file = new Blob([response.data], {type: 'application/pdf'});
                openUrl(URL.createObjectURL(file))

                // setEtiquette2(URL.createObjectURL(file));

                })
                .catch(error => {
                    console.log(error);
                });
        }

    const openUrl = (url,n) => {
        var tab = window.open(url);
        if(!tab){
            alert("Votre navigateur empêche les pop-up destinées à l'envoi de votre colis de s'afficher. Désactivez le blocage pour finaliser l'envoi")
        }
 
    }

  return (
        <div className={styles.container}>
            <div  className={styles.btnBox}>

                <div className={styles.btnTextBox}>
                    <button className={'btn btn-green ' + styles.btnTerminate} 
                    onClick={fetchPdfMondial}
                    >
                    <i className="fas fa-truck-loading"></i>
                    
                     <FormattedMessage 
                         id='printPage.pdfMondial'
                        defaultMessage="Imprimer le bordereau Mondial Relay"
                     />
                    </button>
                    <p>
                        <FormattedMessage 
                            id='printPage.glue'
                            defaultMessage="à coller sur votre colis"
                        />
                    </p>
             
                </div>
                <div className={styles.btnTextBox}>
                    <button className={'btn btn-green ' + styles.btnTerminate} 
                    onClick={fetchPdfRefurb}
                    >
                    <i className="fas fa-truck-loading"></i>
                    <FormattedMessage 
                         id='printPage.pdfRefurb'
                        defaultMessage="Imprimer le bordereau de reprise"
                     />
                   
                    </button>
                    <p>    
                        <FormattedMessage 
                            id='printPage.box'
                            defaultMessage="Imprimer le bordereau de reprise"
                        />
                    </p>
                </div>
            </div>        
            <div className={styles.clientInfo}>
                <p>
                    
                    <FormattedMessage 
                        id='printPage.info'
                        defaultMessage="Vous pouvez retrouver l'ensemble de ces éléments et suivre l'avancement de votre reprise depuis votre espace personnel"
                    />
                    <a href='https://www.ammareal.fr/mon-compte' target='_blank' rel="noopener noreferrer"> ammareal.fr</a> rubrique "Mon compte".</p>
            </div>   
            {/* <iframe src={ etiquette } width="100%" height="100%" type="application/pdf" title="mondialRelay Etiquette"></iframe> */}
            {/* <div className={styles.pdfObjectBox}>
                <object width="100%" height="100%" data={ etiquette } type="application/pdf">   </object>
                <object width="100%" height="100%" data={ etiquette2 } type="application/pdf">   </object>
            </div> */}
            <div className={styles.finalBtn}>
                <button 
                    className={'btn btn-orange shadow'}
                    onClick={()=>{
                        customerDispatch({ type:'reset' });
                        navigationDispatch({ type:'reset' });
                        bookDispatch({ type:'reset' })
                        refreshPage()
                    }}
                >
                    <i className="far fa-check-circle"></i>
                    <FormattedMessage 
                        id='btn.retry'
                        defaultMessage="Je recommence"
                    />
                </button>
            </div>
        </div>
  );
}

