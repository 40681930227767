// const { ClientJS } = require('clientjs');

// // Create a new ClientJS object
// const client = new ClientJS();
// // Get the client's fingerprint id
// const fingerprint = client.getFingerprint();
const { getFingerPrint } = require('./fingerprint') 

function parseJSON(response) {
  return response.json()
}

export async function fetchEventsCreation (data, idCart, capture){
    var stats = { 
      device_id: await getFingerPrint(),
      cartId: idCart,
      data: data,
      isbnCapture: capture,
      origin: "web"
    }

  let returnData
  await fetch(`${process.env.REACT_APP_ADMIN_URL}/apiFront/events/eventsCreation`, {
      method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`
        },
        body: JSON.stringify( stats )
      }) 
      .then(parseJSON)
      .then(function(res) {
        // console.log('request succeeded with JSON response', res);
        returnData = res
      })
      .catch(function(error) {
        console.log('request failed', error)
        returnData = {error:1, message: error.message}
      })
    return returnData

}

export async function fetchOpenAppEvent (){
  // console.log("data event",data)
    var stats = { 
      device_id: await getFingerPrint(),
      origin: "web",
      openAppEvent: true
    }

  let returnData
  await fetch(`${process.env.REACT_APP_ADMIN_URL}/apiFront/events/openAppEvent`, {
      method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`
        },
        body: JSON.stringify( stats )
      }) 
      .then(parseJSON)
      .then(function(res) {
        // console.log('request succeeded with JSON response', res);
        returnData = res
      })
      .catch(function(error) {
        console.log('request failed', error)
      })
    return returnData
}

export async function fetchEventsUpdate(eventName, obj){
  let stats = { 
      eventId: obj.book.eventId?obj.book.eventId:obj.book._id?obj.book._id:null,
      userChoice: eventName
    }

  let returnData
  await fetch(`${process.env.REACT_APP_ADMIN_URL}/apiFront/events/eventsUpdate`, {
      method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`
        },
        body: JSON.stringify( stats )
  }) 
  .then(parseJSON)
  .then(function(data) {
      // console.log('request succeeded with JSON response', data);
      returnData = data
  }).catch(function(error) {
      console.log('request failed', error)
      returnData = {error:1, message:"Une erreur est survenue"};
      
  })
  return returnData
}