import React from 'react';
import { NavigationContext } from '../../store/NavigationContext'
import { CustomerContext } from '../../store/CustomerContext'
import { LanguageContext } from '../../store/LanguageContext';
import styles from './LoginForm.module.css';
import { FormattedMessage } from 'react-intl';
import { GetTranslateText } from '../../utils/translate';

export default function LoginForm() {
    const { navigationDispatch } = React.useContext(NavigationContext);
    const { customerDispatch } = React.useContext(CustomerContext);
    const { languageState } = React.useContext(LanguageContext);

    const [ error, setError ] = React.useState('');

    const [ customer, setCustomer ] = React.useState({
        username: '',
        password: ''
    });
    const [ query, setQuery ] = React.useState('');

    React.useEffect(() => {
        const loginCustomer = () =>
            fetch(`${process.env.REACT_APP_ADMIN_URL}/apiFront/customer/login`, {
                method: 'POST',
                 headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`
                },
                body: JSON.stringify( query )
            })
            .then(res => res.json())  
            .then(res => {
                // console.log(res)
                if (!res || res.error !== 0) {
                    setError(res.message)
                }else{
                    // console.log("loggedCustomer", res)
                    customerDispatch({type:'loggedCustomer', payload: {...res.data, informations: res.informations}})
                    navigationDispatch({type:'customerLoggedIn'})
                }
            }).catch(function(err) {
                console.log(err);
            });
        if (query !== '') {
            loginCustomer();
            setQuery('')
        }
    }, [query, navigationDispatch, customerDispatch ])
    

  return (
    <>  
        <div className={styles.form + ' ph30'}>
            <div>
            <FormattedMessage 
                id="loginForm.alreadyClient"
                defaultMessage="Déjà client sur ammareal.fr ?"
            />
            </div>
          <h3 className={styles.title}>
            <FormattedMessage 
                id="loginForm.connect"
                defaultMessage="Se connecter"
            />
          </h3>
        <form
            // className={styles.loginForm}
            onSubmit={e => {
                e.preventDefault();
                setQuery(customer)
        }}>
            <div className="form-group row">
                {/* <label className="col-4 col-form-label">Email</label> */}
                <div className="col-12">
                    <input type="email" className={"form-control " + styles.input}
                        placeholder="E-mail"
                        onChange={e => setCustomer({...customer, username: e.target.value}) }
                        value={customer.username || ''}
                        required
                        />                
                </div>
            </div>
            <div className="form-group row">
                {/* <label className="col-4 col-form-label">Mot de passe</label> */}
                <div className="col-12">
                    <input type="password" className={"form-control " + styles.input}
                        placeholder={GetTranslateText(languageState,'loginForm.pwd')}
                        onChange={e => setCustomer({...customer, password: e.target.value}) }
                        value={customer.password || ''}
                        required
                        />  
                    <a className={styles.forgotPwd} rel="noopener noreferrer" target='_blank' href='https://www.ammareal.fr/recuperation-mot-de-passe'>
                        <FormattedMessage 
                            id="loginForm.forgottenPwd"
                            defaultMessage="Mot de passe oublié ?"
                        />
                    </a>             
                </div>
            </div>
            
            <div className={styles.buttonRow}>
                <div className={styles.buttonDiv}>
                    <span>
                    <FormattedMessage 
                        id="loginForm.newClient"
                        defaultMessage="Nouveau client"
                    />
                    </span>
                    <p 
                        className={'btn btn-blue'}
                        onClick={()=>navigationDispatch({type:'displayCreateAccount'})}
                    >
                        <FormattedMessage 
                            id="loginForm.signup"
                            defaultMessage="s'inscrire"
                        />
                    </p>
                    {/* <a className={'btn btn-blue'} href='https://www.ammareal.fr/connexion?create_account=1' target="_blank" rel="noopener noreferrer">S'inscrire </a> */}
                </div>
                
                <div className={styles.buttonDiv}>
                    <span>
                        <FormattedMessage 
                            id="loginForm.client"
                            defaultMessage="Client existant"
                        />
                    </span>
                    <button 
                        className={'btn btn-orange'}
                        type="submit" 
                    >{GetTranslateText(languageState,'loginForm.connect')}</button>
               </div>

          </div>
            
        </form>
        {error !== '' ?
            <span className={"alert alert-danger " + styles.error} role="alert">
                {error}
            </span>
        :null}
        </div>

        <button 
          className={'btn btn-grey-dark ' + styles.backButton}
          onClick={ () => navigationDispatch({type:'displayCart'})}
      ><i className="far fa-arrow-alt-circle-left"></i>
        {GetTranslateText(languageState,'btn.backMin')}
      </button>
            
    </>
    );
}

            
                    
                    
                    
                    
                    