import React, { useEffect } from 'react';
import { BookContext } from '../../store/BookContext'
import { NavigationContext } from '../../store/NavigationContext'

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
// import { isAndroid } from "react-device-detect";
import styles from './ScanInput.module.css';
import FeatherIcon from 'feather-icons-react';
//utils

import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import { FormattedMessage } from 'react-intl'
import { GetTranslateText } from '../../utils/translate';
import { LanguageContext } from '../../store/LanguageContext'
const { getFingerPrint } = require('../../utils/fingerprint') 

function ScanInput() {
    const { bookState, bookDispatch } = React.useContext(BookContext);
    const { languageState } = React.useContext(LanguageContext)
    const { navigationDispatch } = React.useContext(NavigationContext);
    const [ search, setSearch ] = React.useState('');
    const [ query, setQuery ] = React.useState('');
    const [ error, setError ] = React.useState(null);
    const [challengeSuccess, setChallengeSuccess] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(false);
    const recaptchaRef = React.useRef(null)

    useEffect(()=>{
        var bookNumber = bookState.cart.length
        var launchIsHumanControl = bookNumber % process.env.REACT_APP_CAPTCHA_THRESHOLD_BOOK;
        if (launchIsHumanControl === 0 && bookNumber !==0 ){
            setChallengeSuccess(false)
        }
    },[bookState])

    React.useEffect(() => {
        const fetchBook = async (retryNumber) =>{
            setIsLoading(true);
            fetch(`${process.env.REACT_APP_ADMIN_URL}/apiFront/getPriceV2`, {
                method: 'POST',
                 headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`
                },
                body: JSON.stringify({
                    isbn: query,
                    appId: "web",
                    device_id: await getFingerPrint(),
                    cartId:  bookState.idCart
                })
            })
            .then( res => {
                  if (res.status >= 400 && res.status < 600) {
                      var error = new Error(res.status)
                      error.response = res
                      throw error
                  }
                 
                 return res.json()
              })
            .then(async data => {
                setIsLoading(false);

                console.log("data !",data)
                if (data.status === 'error') {
                    if(data.code && data.message){
                        setError(`Code erreur ${data.code}: ${data.message}`)
                    } else{
                        setError(`${GetTranslateText(languageState,'ScannerManual.errorMessage')}`)
                    }
                    setQuery('');
                    setSearch('');
                }else{
                    var newData = {...data}
                    newData.isbnClient = search;
                    // console.log('data scan', newData, search)
                    setQuery('');
                    setSearch('');

                    // let eventId = await fetchEventsCreation(newData, bookState.idCart, "manual")
                    if(!data.error){
                        newData._id = data.eventId
                        bookDispatch({type:'currentBook', payload: newData});
                    } else{
                        setError(`${GetTranslateText(languageState,'ScannerManual.errorMessage')}`)

                    }
                    
                }
            })
            .catch(function (err) {
                console.log("ERROR",err);
                setSearch('')
                if(retryNumber !==0) {
                    setTimeout(()=>{
                        fetchBook(retryNumber - 1)
                    },3000)
                }else{
                    setIsLoading(false);
                    setError(`${GetTranslateText(languageState,'ScannerManual.errorMessage')} (Erreur ${err.toString().replace(/\D/g, '')})`)
                }
            });
        }
            if (query !== '' && challengeSuccess) {
                fetchBook(2)
            }    
        
    }, [query, bookDispatch, navigationDispatch, search, challengeSuccess, languageState,bookState.idCart])

    const captchaSubmit = async () => {
        const captchaToken = await recaptchaRef.current.getValue();
        recaptchaRef.current.reset();
        // Pass this token to your server for validation...
        await axios.post(
            `${process.env.REACT_APP_ADMIN_URL}/apiFront/captcha/`,
            { 
                captchaToken
            },
            {
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`
                },
            }
        ).then(res=>{
            setChallengeSuccess(res.data.success)
        })
        .catch(err=>{
            console.log("err captcha",err)
        })
      }

    React.useEffect(() => {
        const CheckISBN = async () => {
            // console.log("search.length",search.length)
            if (search.length === 13 ) {
                setQuery(search)
            }
          };
          CheckISBN();
    },[search]);

 
    
  return (
    <>
        {/* {isAndroid ?
        
            <div>{search}</div>
    
        : */}
            <form
                className={'container ' + styles.form}
                onSubmit={e => {
                    e.preventDefault();
                    setQuery(search)
                }}>
                <div>
                    <FormattedMessage 
                        id="ScannerManual.typeIsbn"
                        defaultMessage="Entrez un code ISBN ou EAN à 13 chiffres."
                    />
                </div>
                <input
                    className={styles.input}
                    type='number'
                    placeholder="9781449479671"
                    autoFocus
                    value={search}
                    onChange={e =>{setError(null); setSearch(e.target.value) }}
                />
                {!challengeSuccess?
                    <ReCAPTCHA
                        hl={languageState}
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                        // size="invisible"
                        onChange={captchaSubmit}
                    />
                    :
                    null
                }
                {!isLoading ?
                <>
                    <button
                        className="btn btn-orange"
                        // type="submit"
                        onClick={()=>{
                            if(search.length === 13){
                                setSearch(search)
                            }else{
                                setError(GetTranslateText(languageState,"ScannerAuto.controlISBN13"))
                            }
                        }}
                    ><i className="fas fa-search"></i>
                        <FormattedMessage 
                            id="ScannerManual.btnSearch"
                            defaultMessage="Recherchez"
                        />
                    </button>
                </>
                :
                <Loader
                    type="Puff"
                    color="#114970"
                    height={30}
                    width={30}
                    // timeout={3000} //3 secs
                />}
                    
                {error?
                <div  className={styles.error} >
                    <FeatherIcon icon='alert-triangle'  size="40"></FeatherIcon>
                    <span>
                       {error}
                    </span>
                </div>  
                :null}
            </form>
    </>
  );
}

export default ScanInput;
