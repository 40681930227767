import React from 'react';
import { NavigationContext } from '../../store/NavigationContext'
import { BookContext } from '../../store/BookContext'
import { CustomerContext } from '../../store/CustomerContext'

//styles
import './widget.css'
import styles from './MondialRelay.module.css';

export default function ModialRelay() {
    const { navigationDispatch } = React.useContext(NavigationContext);
    const { bookState, bookDispatch } = React.useContext(BookContext);
    const { customerState, customerDispatch} = React.useContext(CustomerContext);
    const [ mondialrelay, setMondialrelay ] = React.useState('');
    const [ saveRefurb, setSaveRefurb ] = React.useState(false);
    const [ error, setError]=React.useState(null)
  // function handleSubmit(e) {
  //   // console.log(e.target[0].defaultValue)
  //   // let updatedMondialrelay = {}
  //   // for (let i = 0; i < e.target.length - 1; i++) {
  //   //   updatedMondialrelay = {...updatedMondialrelay, [e.target[i].name]: e.target[i].defaultValue}
  //   // }
  //   setMondialrelay({'relayID': e.target[0].defaultValue})
  // }
      
  React.useEffect(() => {
    const SaveRefurbInfos = () =>
        fetch(`${process.env.REACT_APP_ADMIN_URL}/apiFront/refurb/save`, {
            method: 'POST',
             headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`
            },
            body: JSON.stringify({
              customer: customerState.savedCustomer,
              books: bookState.cart,
              mondialRelayId: mondialrelay
            })
        })
        .then(res => res.json())  
        .then(res => {
            // console.log("res Refurb save front",res)
            if (!res || res.error !== 0) {
                if(res.message){
                    setError(res.message)
                }
            }else{
              bookDispatch({type:'addExpNb', payload:res.data.mondialRelayExpNb})
              bookDispatch({type:'addRefurbId', payload:res.data._id})

              navigationDispatch({type:'print'})
            }
        }).catch(function(err) {
            console.log(err);
        });
    if (saveRefurb === true){
        SaveRefurbInfos();
        setSaveRefurb(false)
    }
}, [mondialrelay, navigationDispatch, bookDispatch, saveRefurb, bookState.cart, customerState.savedCustomer])

      React.useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "//unpkg.com/leaflet/dist/leaflet.js";
        script.async = true;
      
        document.body.appendChild(script);
        // console.log('leafJS append')
        return () => {
          document.body.removeChild(script);
          // console.log('leafJS remove')
        }
      }, []);
      React.useEffect(() => {
        const link  = document.createElement('link');
        link.rel  = 'stylesheet';
        link.type = 'text/css';
        link.href = '//unpkg.com/leaflet/dist/leaflet.css';
      
        document.body.appendChild(link);
        // console.log('leafCSS append')
      
        return () => {
          document.body.removeChild(link);
          // console.log('leafCSS remove')
        }

      }, []);
      
      React.useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "//widget.mondialrelay.com/parcelshop-picker/jquery.plugin.mondialrelay.parcelshoppicker.min.js";
        script.async = true;
      
        document.body.appendChild(script);
        // console.log('mondial append')
      
        // let section = document.getElementById("Zone_Widget").children;
        // console.log("Section",section)
        //  section[0].style.width = '100% !important'
        //  section[0].style.margin = "0 !important"
        return () => {
          document.body.removeChild(script);
          // console.log('mondial remove')
        }
      }, []);

      React.useEffect(() => {
        const script = document.createElement('script');
        setTimeout(()=>{
          script.innerHTML = `$(document).ready(function () {
            $("#Zone_Widget").MR_ParcelShopPicker({
                Target: "#Target_Widget",
                Brand: "F2AMMARL",
                Country: "FR",
                ColLivMod: "REL",
                NbResults: "7",
                Responsive: true, 
                ShowResultsOnMap: true,
                OnParcelShopSelected:
                  function(data) {
                    $("#cb_ID").attr('value', data.ID);
                    // $("#cb_Nom").attr('value', data.Nom);
                    // $("#cb_Adresse").attr('value', data.Adresse1 + ' ' + data.Adresse2);
                    // $("#cb_CP").attr('value', data.CP);
                    // $("#cb_Ville").attr('value', data.Ville);
                    // $("#cb_Pays").attr('value', data.Pays);
                    $("#nextButton").show();
                  },
                CSS: "1"
            })
        })`

       

         }, 1000)
        
        script.async = true;
      
        document.body.appendChild(script);
        // console.log('mycode append')
      

        return () => {
          document.body.removeChild(script);
          // console.log('mycode remove')
        }
      }, []);

  return (
    <>
            {/* <button onClick={append}>Append JS</button> */}
            <div className={styles.widget} id='Zone_Widget'></div>          
            <input type="hidden" id="Target_Widget" /> 

            <form
            id='form-mondialRelay'
            onSubmit={e => {
                e.preventDefault();
                console.log("target vakue mondialRelay",e.target[0].defaultValue)
                setMondialrelay(e.target[0].defaultValue)
                setSaveRefurb(true)
        }}> 

            <input type="hidden" id="cb_ID" name='ID'/>  
            {/* <input type="hidden" id="cb_Nom" name='Nom'/>
            <input type="hidden" id="cb_Adresse" name='Adresse'/>
            <input type="hidden" id="cb_CP" name='CP'/>
            <input type="hidden" id="cb_Ville" name='Ville'/>  
            <input type="hidden" id="cb_Pays" name='Pays'/>    */}
              
            </form>
            {error?
                <div>   
                    <div className={"alert alert-danger " + styles.error} role="alert">
                        {error}
                    </div>
                    <button 
                        id='tryAgain'
                        className=' btn btn-orange '
                        
                        onClick={ () => {
                            customerDispatch({type:"reset"});
                            navigationDispatch({type:'displayLogin'})}
                        }
                    >Je recommence<i className="far fa-arrow-alt-circle-right"></i>
                    </button>
                </div>
            :  
            <button 
                id='nextButton'
                className={styles.nextButton + ' btn btn-orange '}
                form='form-mondialRelay'
                type='submit'
                // onClick={ () => navigationDispatch({type:'mondialRelayInfo'})}
            >
                Suite
            <i className="far fa-arrow-alt-circle-right"></i></button>}
          
      
    </>
  );
}

