import React from 'react';

const CustomerContext = React.createContext();
const localState = JSON.parse(localStorage.getItem('customerState'));
// console.log("localState",localState)

const initialState = {
    customer: {email:{_cdata:null}, id: {_cdata:null}, firstname:{_cdata:null}, lastname: {_cdata:null}, address:null, informations:null}
}

function customerReducer(state, action){
    switch (action.type) {
        case 'reset': {
            localStorage.removeItem("customerState")
            return initialState
        }
        case 'loggedCustomer': {
            const updatedCustomer = action.payload;
            return {
                ...state,
                customer: updatedCustomer
            }
        }
        case 'savedCustomer': {
            const updatedCustomer = action.payload;
            return {
                ...state,
                savedCustomer: updatedCustomer
            }
        }
        case 'infoCustomer': {
            const updatedInfo = action.payload;
            return {
                ...state,
                customerInfo: updatedInfo
            }
        }
        default: {
            return state;
        }
    }
}

function CustomerProvider({children}) {
    const [customerState, customerDispatch] = React.useReducer(customerReducer, localState || initialState)

    React.useEffect(() => {
        localStorage.setItem('customerState', JSON.stringify(customerState))
    }, [customerState])

    return (
      <CustomerContext.Provider value={{customerState, customerDispatch}}>
          {children}
      </CustomerContext.Provider>
    )
}

export { CustomerProvider, CustomerContext }