import React from 'react';
import { NavigationContext } from '../../store/NavigationContext'
import { CustomerContext } from '../../store/CustomerContext' 
import styles from './CheckoutPage.module.css'
import { BookContext } from '../../store/BookContext'
import { LanguageContext } from '../../store/LanguageContext';

import './CheckoutPage.css';

import LogoHeader from '../../components/Headers/LogoHeader/LogoHeader'
import { FormattedMessage } from 'react-intl';
import { GetTranslateText } from '../../utils/translate'

export default function CheckoutPage() {
  const { bookState, bookDispatch } = React.useContext(BookContext)
    const { navigationState, navigationDispatch } = React.useContext(NavigationContext)
    const { customerState } = React.useContext(CustomerContext)
    const {languageState } = React.useContext(LanguageContext)
    let filteredCart = bookState.cart.filter(book => book.userChoice === 'sold')
    const totalPrice = filteredCart.reduce((count, curItem) => count + curItem.price, 0)
    const minimumPriceNeeded = 15 - totalPrice
    var pricePartner = (Math.round(totalPrice * 0.2 * 100)/100).toFixed(2);
    
    

  return (
    <>
    {navigationState.displayCheckout ?
      <div className={styles.container}>
        <LogoHeader/>
        <div className={styles.content  + '  ph30'}>
          <h1 className={styles.title}>{GetTranslateText(languageState,"cartRefurb")}</h1>
    <div className={styles.articles}>
      {customerState.savedCustomer.firstName}, 
        vous avez 
        {filteredCart.length} 
        articles à reprendre
      </div>
          <div className={styles.bookBlock}>
          {filteredCart.map((book, index) => {
              return <div className={styles.bookItem} key={book.id}>
                      <div className={styles.bookImgTitleAuthor}>
                        <img 
                            className={styles.imageResponsive}
                            src={book.image} 
                            alt={book.title}/>
                        <div>
                          <span className={styles.bookTitle}>{book.title}</span>
                          {book.authors ?
                            <p className={styles.authors}><span>de </span>
                                {book.authors.map((author, index) => {
                                    return index + 1 < book.authors.length ?
                                        <span key={index}>{author.split(', ')[1]} {author.split(', ')[0]} et </span>
                                    :
                                        <span key={index}>{author.split(', ')[1]} {author.split(', ')[0]}</span>
                                })}
                            </p>
                          :null}
                        </div>
                      </div>
                      <div className={styles.actions}>
                      {/* <i className={"far fa-eye " + styles.eyeLogo}
                        alt='logo'
                        // onClick={() => bookDispatch({ type:'deleteBook', payload:book.id })}
                        >
                        </i> */}
                        <i 
                          className={'far fa-trash-alt ' + styles.binLogo} 
                          alt='logo'
                          onClick={() => bookDispatch({ type:'deleteBook', payload:book.id })}>
                        </i>
                        <span className={styles.bookPrice}>{book.price.toFixed(2).toString().replace('.', ',')} <sup>€</sup> 
                        </span>
                      </div>
                    </div>
          })}
          </div>
          
          {minimumPriceNeeded > 0 ?
            <div className={styles.minimumPriceBlock}>
              <i className={"fas fa-exclamation-triangle " + styles.warningLogo}></i>
              <div className={styles.minimumPriceSentence}>
                {GetTranslateText(languageState,'cartScreen.controlePrice.1')} 
                 <span className={styles.minimumPrice}>{minimumPriceNeeded.toFixed(2).toString().replace('.', ',')} <sup>€</sup></span>
                 {GetTranslateText(languageState,'cartScreen.controlePrice.2')} 
                 </div>
            </div>
          
          :null}
{/* 
          <div className={styles.totalPrice}>
            <span>Total reprise</span>
            <span>{totalPrice.toFixed(2).toString().replace('.', ',')} <sup>€</sup></span>
          </div>
          <div className={styles.conditionSentence}>
            sous réserve que votre envoi et son état soit conforme à votre déclaration !!
          </div> */}
            <div className={styles.totalPrice}>
                <p className={styles.totalDesc}>
             {GetTranslateText(languageState,'cartScreen.total')}
                  : <span className={styles.priceCustomer}>{totalPrice.toFixed(2).toString().replace('.', ',')}&nbsp;<sup>€</sup></span>
                </p>
                <p className={styles.infoPrice}>
                    {GetTranslateText(languageState,'checkoutPage.giftPartner1')}
                    <span className={styles.pricePartner}> {pricePartner.toString().replace('.', ',')} <sup>€</sup></span>
                    {GetTranslateText(languageState,'checkoutPage.giftPartner2')}
                </p>
                {/* <span>Total reprise</span> */}
                {/* <span>{totalPrice.toFixed(2).toString().replace('.', ',')} <sup>€</sup></span> */}
            </div>
        <div className={styles.conditionSentence}>
          <i className={"fa fa-exclamation-triangle"} style={{paddingRight:5}}></i>
          {GetTranslateText(languageState,'cartScreen.conditionRefurb')}
            {/* sous réserve que votre envoi et son état soit conforme à votre déclaration */}
        </div>

          

          <div className={styles.buttonRow}>
            
              <button 
                  className={'btn btn-blue'}
                  onClick={ () => navigationDispatch({type:'displayManual'})}
              ><i className="far fa-arrow-alt-circle-left"></i>
                 <FormattedMessage 
                    id="btn.continue"
                    defaultMessage="Continuer"
                  /> 
              </button>
            {minimumPriceNeeded > 0 ?
              <button 
              className={'btn btn-orange'}
              disabled
          ><i className="far fa-check-circle"></i> 
            <FormattedMessage 
              id="btn.finalize"
              defaultMessage="Finaliser"
            />
          </button>
            :
            <button 
            className={'btn btn-orange'}
            onClick={ () =>{navigationDispatch({type:'customerEndProcess'})}}
        ><i className="far fa-check-circle"></i> {GetTranslateText(languageState,'btn.finalize')}</button>
            }  
          </div>              
        </div>
      </div>
    :null}
    </>
  );
}
