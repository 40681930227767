import React from 'react';
import { NavigationContext } from '../../store/NavigationContext' 

import LogoHeader from '../../components/Headers/LogoHeader/LogoHeader'

import styles from './IntroPage.module.css';

import illustration from '../../assets/images/Illustration.svg'
import pictoColis from '../../assets/images/Picto-Colis.svg'
import pictoAlertCircle from '../../assets/images/alert-circle.svg'
import picto1 from '../../assets/images/Picto-Coeur.svg'
import pictoDecitre from '../../assets/images/Lire-et-Sourire.png'
import pictoBsf from '../../assets/images/Logo-BSF.svg'
import pictoMm from '../../assets/images/Logo-MetM.svg'
import pictoSpf from '../../assets/images/Logo-SPF.svg'
import pictoCodeBarres from '../../assets/images/Picto-CodeBarres.svg'
import pictoValidation from '../../assets/images/Picto-Validation.svg'
import pictoPouce from '../../assets/images/Picto-Pouce.svg'
import pictoPackage from '../../assets/images/package.svg'
import pictoPrint from '../../assets/images/Picto-Imprimante.svg'
//Trad
import {FormattedMessage} from 'react-intl';

export default function IntroPage(props) {
    const [step, setStep] = React.useState(1)
    const {navigationDispatch } = React.useContext(NavigationContext)
        return (
       
                <>
                    <LogoHeader/>
                    <div className={'ph30 ' + styles.container }>
               
                        {step===1?
                        <div className={styles.content}>
                            <p className={styles.title}>
                                <FormattedMessage 
                                    id="intro.slide1"
                                    defaultMessage="Offrez une seconde vie à vos livres"
                                    description="intro screen"
                                />
                            </p>
                            <img className={styles.illustration} alt="isllustration" src={illustration}></img>
                        </div>
                        :step===2?
                        <div className={styles.content}>
                            <div className={styles.contentStep2}>
                                <div  className={styles.boxPictoText}>
                                    <img src={pictoColis} alt="picto"/>
                                    <p>
                                    <FormattedMessage 
                                        id="intro.slide2.1"
                                        defaultMessage="Nous achetons vos livres et vous nous les envoyez sans payer le transport."
                                        description="intro screen"
                                    />
                                    </p>
                                </div>
                                <div className={styles.boxPictoText}>
                                    <div className={styles.pictoEuro}>
                                        €
                                    </div>
                                    <p>
                                        <FormattedMessage 
                                            id="intro.slide2.2"
                                            defaultMessage="Vous êtes payé dès que vos livres sont reçus et triés."
                                            description="intro screen"
                                        />
                                    </p>
                                </div>
                                <div  className={styles.boxPictoText}>
                                    <div className={styles.alertCircle}> 
                                        <img className={styles.alertCircleImg} src={pictoAlertCircle} width="27px" alt="picto" />
                                    </div>
                                        <p>
                                            <FormattedMessage 
                                                id="intro.slide2.3"
                                                defaultMessage="Nous ne reprenons que les livres en très bon état."
                                                description="intro screen"
                                            />
                                        </p>
                                </div>
                            </div>
                        </div>
                        :step===3?
                        <div className={styles.content}>
                            <div className={styles.contentStep2}>
                                <div  className={styles.boxPictoText}>
                                    <img src={picto1} alt="picto"/>
                                    <p>
                                        <FormattedMessage 
                                            id="intro.slide3.1"
                                            defaultMessage="Pour chaque euro que nous vous versons, nous donnons 20 centimes à l’un de nos partenaires caritatifs."
                                            description="intro screen"
                                        />
                                    </p>
                                </div>
                            </div>
                            <div className={styles.boxPicto}>
                                <img src={pictoDecitre} width="55px" alt="picto" />
                                <img src={pictoBsf} width="164px" height="41" alt="picto" />
                                <img src={pictoMm} width="135px" height="60" alt="picto" />
                                <img src={pictoSpf} width="104px" height="82" alt="picto" />
                            </div>
                        </div>
                        :step===4?
                        <div className={styles.contentNoMarginTop4}>
                            <h1 className={styles.title4}>
                            <FormattedMessage 
                                id="intro.slide4.1"
                                defaultMessage="Comment faire ?"
                                description="intro screen"
                            />
                            </h1>
                            <div className={styles.content4}>
                                <div  className={styles.boxPictoText4}>
                                    <img src={pictoCodeBarres} alt="picto"/>
                                    <p>
                                        <FormattedMessage 
                                            id="intro.slide4.2"
                                            defaultMessage="Scannez le code barres ou saisissez le numéro ISBN."
                                            description="intro screen"
                                        />
                                    </p>
                                </div>
                                <div className={styles.boxPictoText4}>
                                <img src={pictoValidation} alt="picto"/>
                                    <p>
                                        <FormattedMessage 
                                            id="intro.slide4.3"
                                            defaultMessage="Le prix de reprise s’affiche automatiquement. S’il vous convient, ajoutez-le au panier."
                                            description="intro screen"
                                        />
                                    </p>
                                </div>
                                <div className={styles.boxPictoText4}>
                                    <img src={pictoPouce} alt="picto" />
                                    <p>
                                       <FormattedMessage 
                                            id="intro.slide4.4"
                                            defaultMessage="Vous pouvez nous les envoyer dès que votre panier atteint {REACT_APP_PRICE}€."
                                            description="intro screen"
                                            values={{
                                                REACT_APP_PRICE: process.env.REACT_APP_PRICE,
                                            }}
                                        />
                                    </p>
                                </div>
                                <div className={styles.boxPictoText4}>
                                    <div className={styles.pictoPackage}> 
                                        <img src={pictoPackage} alt="picto" />
                                    </div>
                                    <p>
                                    <FormattedMessage 
                                        id="intro.slide4.5"
                                        defaultMessage="Emballez bien les livres dans un seul carton."
                                        description="intro screen"
                                    />
                                    </p>
                                </div>
                                <div className={styles.boxPictoText4}>
                                <img src={pictoPrint} alt="picto"/>
                                    <p>
                                        <FormattedMessage 
                                            id="intro.slide4.6"
                                            defaultMessage="Imprimez et collez l’étiquette sur le colis et déposez-le dans le Mondial Relay le plus proche"
                                            description="intro screen"
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                        :null
                        }
                        <div className={styles.bottom}>
                            <div className={styles.boxButton}>
                                <button className={"btn " + styles.skipBtn}
                                    onClick={()=>{
                                        navigationDispatch({type: "displayIntro", payload: false})
                                    }}
                                >
                                    <FormattedMessage 
                                        id="btn.skip"
                                        defaultMessage="PASSEZ"
                                        description="button"
                                    />
                                </button>
                            </div>
                            
                            <div className={styles.dots}>
                                <div className={styles.dot + " "+(step===1?styles.activated:null)}></div>
                                <div className={styles.dot + " "+(step===2?styles.activated:null)}></div>
                                <div className={styles.dot + " "+(step===3?styles.activated:null)}></div>
                                <div className={styles.dot + " "+(step===4?styles.activated:null)}></div>


                            </div>
                            <div className={styles.boxButton + " " + styles.orange}>
                                <button 
                                    className={'btn btn-orange' }
                                    onClick={ () => {
                                        if(step===4){
                                            navigationDispatch({type: "displayIntro", payload: false})
                                        }else{
                                            setStep(step+1)
                                        }
                                }}
                                >
                                    <FormattedMessage 
                                        id="btn.next"
                                        defaultMessage="SUITE"
                                        description="button"
                                    />
                                    <i className="far fa-arrow-alt-circle-right"></i> 
                                </button>
                            </div>
                        </div>
                    </div>
                </>
         );
}

