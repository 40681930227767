import React from 'react';
import { NavigationContext } from '../../store/NavigationContext' 
import styles from './HomePage.module.css'

import LogoHeader from '../../components/Headers/LogoHeader/LogoHeader'
import HomeCart from '../../components/HomeCart/HomeCart'
import {isAndroid} from 'react-device-detect';
import keyBoardIcon from '../../assets/images/saisie-manuelle.svg'
import scannerIcon from '../../assets/images/code-barre.svg'
//Trad
import {FormattedMessage} from 'react-intl';

export default function HomePage() {
    const {navigationDispatch } = React.useContext(NavigationContext)

        return (
            <>
                <LogoHeader/>
                <div className={'ph30 ' + styles.container }>
                    <div className={styles.content}>
                    <h1 className={styles.title}>
                        <FormattedMessage 
                            id="home.screen.1"
                            defaultMessage="Ajoutez un livre"
                            description="home screen"
                        />
                    </h1>
                        <div className={styles.buttons}>
                        {isAndroid?
                            <div className={styles.button}>
                                <img className={styles.scannerImg} alt="Scanner" src={scannerIcon}></img>
                                <button 
                                className="btn btn-blue"
                                onClick={() => navigationDispatch({ type: 'displayBarCodeScanner'})}
                                >
                                <FormattedMessage 
                                    id="home.screen.2"
                                    defaultMessage="Scannez le code-barres"
                                    description="home screen"
                                />
                                </button>
                            </div>
                            :null}
                        <div className={styles.button}>
                            <img className={styles.keyboardImg} alt="keyboard" src={keyBoardIcon}></img>
                            <button 
                            className="btn btn-blue"
                            onClick={() => navigationDispatch({ type: 'displayManual'})}
                            >
                                <FormattedMessage 
                                    id="home.btn.scanManually"
                                    defaultMessage="Entrez manuellement"
                                    description="Entrez manuellement button"
                                />
                            </button>
                        </div>
                        </div>
                        <div 
                            className={styles.cdg}
                            onClick={() => navigationDispatch({ type: 'displayRefurbCondition'})}
                        >
                            <FormattedMessage 
                                id="home.btn.cgau"
                                defaultMessage="Nos conditions de rachat"
                                description="Nos conditions de rachat button"
                            />
                        </div>
                    </div>
                <HomeCart/>
                </div>
            </>
          )
}
  

