import fr from "../assets/translations/fr.json"
import es from "../assets/translations/es.json"

const data = {
    fr,
    es
  };

// const deviceLanguage = navigator.language || navigator.userLanguage;
// console.log("language" , navigator.language || navigator.userLanguage)
// export const  language = deviceLanguage.split("-")[0] // language without region code

export const GetTranslateText = (lang, keyWord) => {
    return data[lang].hasOwnProperty(keyWord)
      ? data[lang][keyWord]
      : data["fr"][keyWord];
  };