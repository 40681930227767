import React from 'react';
import { NavigationContext } from '../../store/NavigationContext' 

import styles from './createAccountPage.module.css'

import LogoHeader from '../../components/Headers/LogoHeader/LogoHeader'
import CreateAccountForm from '../../components/InfoForm/createAccountForm'
import { FormattedMessage } from 'react-intl';

export default function CreateAccountPage() {

    const { navigationState } = React.useContext(NavigationContext)
    
    if (navigationState.displayCreateAccountPage){
        return(
            <div className={styles.container}>
                <LogoHeader/>
                <div className={styles.content}>
                    <h1 className={styles.title}>
                    <FormattedMessage 
                        id="accountCreationForm.title"
                        defaultMessage="Création d'un compte utilisateur"
                    />
                    </h1>  
                    <CreateAccountForm/>
                </div>
            </div>
        )
    }else{
        return <></>
    }
}

