import React from 'react';
import BarcodeScannerComponent from "react-webcam-barcode-scanner";
import { BookContext } from '../../store/BookContext';
import FeatherIcon from 'feather-icons-react';
import styles from '../ScanInput/ScanInput.module.css';
import Loader from 'react-loader-spinner';

function BarCodeScanner(props) {
 
  const { bookState, bookDispatch } = React.useContext(BookContext);
  const [ query, setQuery ] = React.useState('');
  const [ error, setError ] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
      const fetchBook = (retryNumber) => {
        setIsLoading(true);
       
        fetch(`${process.env.REACT_APP_ADMIN_URL}/apiFront/getPriceV2`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`
            },
            body: JSON.stringify({
            isbn: query,
            appId: "web"
            })
        })
          .then(res => {
                if (res.status >= 400 && res.status < 600) {
                    var error = new Error(res.status)
                    error.response = res
                    throw error
                }
               return res.json()
            })
          .then(async data => {
            setIsLoading(false);

            if (data.status === 'error') {
                if(data.code && data.code === 501){
                    setError('Numéro non reconnu !')
                } else{
                    setError(`Un problème est survenu, réessayez dans un instant. Si le problème persiste, merci de nous contacter. (Erreur ${data.code})`)
                }
            }else{
                var newData = {...data}
                newData.isbnClient = query;
                // console.log('data scan', newData, search)
                // let eventId = await fetchEventsCreation(newData, bookState.idCart, "auto")
                  if(!data.error){
                        newData._id = data.eventId
                        bookDispatch({type:'currentBook', payload: newData});
                    } else{
                        setError('Un problème est survenu, réessayez dans un instant. Si le problème persiste, merci de nous contacter.')
                    }
            }
          })
          .catch(function (err) {
            // console.log("ERROR",err)
            if(retryNumber !==0) {
                setTimeout(()=>{
                    fetchBook(retryNumber - 1)
                },3000)
            }else{
                setIsLoading(false);
                setError(`Un problème est survenu, réessayez dans un instant. Si le problème persiste, merci de nous contacter. (Erreur ${err.toString().replace(/\D/g, '')})`)
            }
            });
      }
        if (query !== '') {
            fetchBook(2)
        }    
      
  }, [query, bookDispatch,setError, bookState.idCart])

    const checkResult=(result)=>{
        var regex = /^[0-9]{10,13}$/
        if(regex.test(result)){
            setError(null); 
            setQuery(result)
        }
    }
    return (
     <>
            {!isLoading?<BarcodeScannerComponent
                width="100%"
                height="300"
                onUpdate={(err, result) => {
                if (result){ 
                    // console.log("scan Result",result)
                    checkResult(result.text)
                    }
                }}
            />
            :
            <div style={{display: 'flex', justifyContent: "center"}}>
                <Loader
                        type="Puff"
                        color="#114970"
                        height={30}
                        // width={100%}
                        // timeout={3000} //3 secs
                    />
            </div>
            }
            {error?
                <div  className={styles.error} >
                    <FeatherIcon icon='alert-triangle'  size="40"></FeatherIcon>
                    <span>
                        {error}
                    </span>
                </div>  
            :null}
    </>
    
        
  )
}
 
export default BarCodeScanner;