import React from 'react';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getCart } from '../utils/getCart'

const BookContext = React.createContext();

const initialState = {
    mondialRelayExpNb: null,
    refurbId: null,
    book: null,
    lastAction: null,
    cart: [],
    tooHeavy: false,
    idCart: uuidv4()
}



 function bookReducer(state, action){
    switch (action.type) {
        case 'reset': {
            localStorage.removeItem("bookState")
            return initialState
        }
        case 'currentBook': {
            const updatedBook = action.payload;
            updatedBook.id = Date.now();
            return {
                ...state,
                book: updatedBook
            }
        }
        case 'tooHeavy': {
            return {
                ...state,
               tooHeavy: action.payload
            }
        }
        case 'sellBook': {
            const updatedCart = [...state.cart];
            const updatedBook = {...state.book, 'userChoice': 'sold'}
            updatedCart.push({ ...updatedBook});
            return {
                ...state,
                book: null,
                cart: updatedCart,
                lastAction: 'SELL',
            }
        }
        
        case 'keepBook': {
            const updatedCart = [...state.cart];
            const updatedBook = {...state.book, 'userChoice': 'kept'}
            updatedCart.push({ ...updatedBook});
            return {
                ...state,
                book: null,
                cart: updatedCart,
                lastAction: 'KEEP',
            }
        }
        case 'deleteBook': {
            const oldCart = [...state.cart];
            const newCart = oldCart.filter(cartItem => cartItem._id !== action.payload);

            return {
                ...state,
                cart: newCart,
            }
        }
        case 'addExpNb': {
            // const oldCart = [...state.cart];
            // const newCart = oldCart.filter(cartItem => cartItem.id !== action.payload);
            return {
                ...state,
                mondialRelayExpNb: action.payload,
            }
        }
        case 'addRefurbId': {
            // const oldCart = [...state.cart];
            // const newCart = oldCart.filter(cartItem => cartItem.id !== action.payload);
            return {
                ...state,
                refurbId: action.payload,
            }
        }
        case 'initCart':{
            return {
                ...state,
                cart: action.payload,
            }
        }
        default: {
            return state;
        }
    }
}

function BookProvider({children}) {
    const [bookState, bookDispatch] = React.useReducer(bookReducer, initialState)

     //retreive Cart history
    useEffect(()=>{
        getCart()
        .then(res=>{
            // console.log("res getCart",res)
            if(!res.data.error){
                // let localState = JSON.parse(localStorage.getItem('bookState'));
                bookDispatch({type:'initCart', payload:  res.data.cart})
                // bookDispatch({type:'initCart', payload : res.data.cart})
            }else{
                bookDispatch({type:'reset'})
            }
        })
    
    },[])
    
    React.useEffect(() => {
        localStorage.setItem('bookState', JSON.stringify(bookState))
    }, [bookState])

    return (
      <BookContext.Provider value={{bookState, bookDispatch}}>
          {children}
      </BookContext.Provider>
    )
}

export { BookProvider, BookContext, bookReducer }