import React from 'react';
//store
import { NavigationContext } from '../../store/NavigationContext'
import { BookContext } from '../../store/BookContext'
import { CustomerContext } from '../../store/CustomerContext'

import ScanInput from '../../components/ScanInput/ScanInput'
import BookChoice from '../../components/BookChoice/BookChoice'
import styles from './ScanPage.module.css'
import BackHeader from '../../components/Headers/BackHeader/BackHeader';
import BarCodeScanner from "../../components/BarCodeScanner/BarCodeScanner";
import { FormattedMessage } from 'react-intl';

export default function ScanPage() {
    const { navigationState, navigationDispatch } = React.useContext(NavigationContext)
    const { bookState } = React.useContext(BookContext)
    const { customerState } = React.useContext(CustomerContext)

    const needCheckOut = bookState.cart.filter(function(book){ return book.userChoice === "sold" }).length;
        // console.log("SCANPAGE tooHeavy", bookState.tooHeavy)
    
   React.useEffect(()=>{
    if(bookState.tooHeavy){
         navigationDispatch({'type':"displayCart"})
    }
},[navigationDispatch,bookState.tooHeavy])

  return (
    <>
    
        {navigationState.displayScanPage?

            // <div className={styles.background} >  
            //     <div className={styles.content + ' ph30'}>
            <>
        <BackHeader/>
            {!bookState.book ?
            <div className={styles.background} >  
                <div className={styles.content + ' ph30'}>
                    {navigationState.displayWebcam ?

                        <BarCodeScanner/>

                    :null}

                    {navigationState.displayManual ?

                         <ScanInput/>

                    :null}
                    
                    {bookState.cart.length > 0 && needCheckOut > 0 ?
                    <div  className={styles.terminate}>
                        <button 
                            className={'btn btn-green ' + styles.btnTerminate}
                            onClick={ () =>{
                                    if(customerState.savedCustomer){
                                        navigationDispatch({type:'customerInfo'})
                                    }else{
                                        navigationDispatch({type:'displayCart'})
                                    }
                                }
                            } 
                        >
                            <i className="fas fa-shopping-bag"></i> 
                            <FormattedMessage 
                            id="btn.finalizeBtn"
                            defaultMessage="Finaliser la reprise" 
                            />
                        </button>
                    </div>
                    :null}
                </div>
            </div>
                :
                <div className={styles.backgroundBookChoice} >  
                    <div className={styles.contentBookChoice + ' ph30'}>
                        <BookChoice/>
                    </div>
                </div>
                }
        </>
        :
        null
        }
    </>
  );


}
