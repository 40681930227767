import React from 'react';
import { NavigationContext } from '../../store/NavigationContext'
import { CustomerContext } from '../../store/CustomerContext'

import styles from './EndProcessPage.module.css'

import LogoHeader from '../../components/Headers/LogoHeader/LogoHeader'

import MondialRelay from '../../components/MondialRelay/MondialRelay'
import PdfEtiquette from '../../components/PdfEtiquette/PdfEtiquette'

export default function EndProcessPage() {
    const { navigationState } = React.useContext(NavigationContext)
    const { customerDispatch } = React.useContext(CustomerContext)
    
    //Clean CustomerState context
    React.useEffect(()=>{
      customerDispatch({type:'reset'}) 
    },[customerDispatch])
  
    return (
    <>
    {navigationState.displayEndProcess ?
      <div className={styles.container}>
        <LogoHeader/>
        <div className={styles.content+ '  ph30'}>
          <h1 className={styles.title}>Sélection Point Relais</h1>
            <div className={styles.step}>
              <p>
                Commencez à saisir votre ville et sélectionnez celle qui correspond à votre recherche dans la liste proposée.
              </p>
              <p>
                Après avoir cliqué sur Chercher, sélectionnez le point Relais qui vous intéresse et poursuivez la navigation.
              </p>
            </div>
            <MondialRelay/>
              
        </div>
      </div>
    :navigationState.displayPrint?
      <div className={styles.container}>
      <LogoHeader/>
      {/* <div className={styles.content + ' ph30'}> */}
        {/* <h1 className={styles.title}>Print Etiquette !</h1> */}
        
        <PdfEtiquette/>

        
      </div>
    // </div>
    :null}
    </>
  );
}
