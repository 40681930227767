import React from 'react';
import styles from './CgauPage.module.css'
import BackHeader from '../../components/Headers/BackHeader/BackHeader';
import { NavigationContext } from '../../store/NavigationContext'
import { FormattedMessage } from 'react-intl';


export default function CgauPage() {
  const { navigationState } = React.useContext(NavigationContext)
  
  return (
    <>
        {navigationState.displayCGAU ?
        <div className={styles.parentContent}>
            <BackHeader/>
            <div className={styles.content}>  
        <p className={styles.titleMedium + " " + styles.textAlignCenter + " " + styles.mT30} >
          <FormattedMessage
            id='cgau.title'
            defaultMessage="Reprise d’articles d’occasion contre paiement Ammareal"
          />
        </p>
        <p className={styles.art}>
          <FormattedMessage
            id='cgau.art1.title'
            defaultMessage="Article 1 – Champ d’application"
          />
        </p>
        <p >
          <FormattedMessage
            id='cgau.art1.1'
            defaultMessage="Les présentes Conditions Générales d’Utilisation et d’Achat d’Occasion s’appliquent de façon exclusive à l’utilisation de l’application CIRCULAREAL (ci-après le « l’Application ») éditée par la société AMMAREAL SAS, domiciliée au 31 rue Marcelle Henry, 91200 Athis Mons, inscrite au registre du commerce et des sociétés d’EVRY sous le numéro 797 906 906."
          />
        </p>
        <p className={styles.bold+ " " + styles.mT10+ " " + styles.mB10}>
          <FormattedMessage
            id='cgau.art1.2'
            defaultMessage="La vente en ligne par des particuliers est encadrée par les dispositions générales du Code de commerce, du Code général des impôts et du Code du travail."
          />
          <br></br><br></br>
          <FormattedMessage
            id='cgau.art1.3'
            defaultMessage="Dans le cadre de son service d’achat d’articles d’occasion, AMMAREAL pourra refuser toute vente dès lors que l'utilisation de ce service présentera un caractère assimilable à une activité commerciale."
          />
          <br></br><br></br>
          <FormattedMessage
            id='cgau.art1.4'
            defaultMessage="Le caractère commercial d'une activité est défini par son caractère habituel et son but lucratif."
          />
        </p>
        <p>
          <FormattedMessage
            id='cgau.art1.5'
            defaultMessage="Les présentes Conditions Générales d’Achat régissent ainsi exclusivement l’achat de"
          />
          {/* <br></br><br></br> */}
        </p>
        <p>
        <FormattedMessage
          id='cgau.list1.title'
          defaultMessage="AMMAREAL reprend vos livres d’occasion en très bon état voire parfait état, c’est à dire"
        />:
          <ul>
            <li>
              <FormattedMessage
                id='cgau.list1.1'
                defaultMessage="Avec codes-barres uniquement"
              />
            </li>
            <li>
              <FormattedMessage
                id='cgau.list1.2'
                defaultMessage="Avec une couverture et tranche propres"
              />
            </li>
            <li>
              <FormattedMessage
                id='cgau.list1.3'
                defaultMessage="Sans traces d’usures ou très légères"
              />
            </li>
            <li>
              <FormattedMessage
                id='cgau.list1.4'
                defaultMessage="Sans pliures ou très peu et discrètes"
              />
            </li>
            <li>
              <FormattedMessage
                id='cgau.list1.5'
                defaultMessage="Sans pages déchirées, volantes ou manquantes"
              />
            </li>
            <li>
              <FormattedMessage
                id='cgau.list1.6'
                defaultMessage="Sans traces d’humidité, ni autres salissures"
              />
            </li>
            <li>
              <FormattedMessage
                id='cgau.list1.7'
                defaultMessage="Sans mauvaises odeurs"
              />
            </li>
            <li> 
              <FormattedMessage
                id='cgau.list1.8'
                defaultMessage="Avec une reliure intacte pas de reliure cassure nette"
              />
              <FormattedMessage
                id='cgau.list1.9'
                defaultMessage="à l’intérieur du livre ou décollée"
              />
            </li>
            <li>
              <FormattedMessage
                id='cgau.list1.10'
                defaultMessage="Sans lignes surlignées ou soulignées, sauf si très rares"
              />
            </li>
            <li>
              <FormattedMessage
                id='cgau.list1.11'
                defaultMessage="Complet si vendu avec un CD/ DVD/ jeu/ cartes, autres"
              />
            </li>
            
          </ul>
        </p>
        <p>
          <FormattedMessage
            id='cgau.list2.1'
            defaultMessage="Aussi, AMMAREAL peut refuser l’achat de"
          />:

          <ul>
            <li>
              <FormattedMessage
                id='cgau.list2.2'
                defaultMessage="Livres neufs"
              />
            </li>
            <li>
              <FormattedMessage
                id='cgau.list2.3'
                defaultMessage="Livres en multiples exemplaires (cf. article 3.3)"
              />
            </li>
            <li>
              <FormattedMessage
                id='cgau.list2.4a'
                defaultMessage="Les anciens livres de bibliothèques"
              />
            </li>
            <li>
              <FormattedMessage
                id='cgau.list2.4'
                defaultMessage="Magazines"
              />
            </li>
            <li>
              <FormattedMessage
                id='cgau.list2.5'
                defaultMessage="Catalogues"
              />
            </li>
            <li>
              <FormattedMessage
                id='cgau.list2.6'
                defaultMessage="Calendriers"
              />
            </li>
            <li>
              <FormattedMessage
                id='cgau.list2.7'
                defaultMessage="Agendas"
              />
            </li>
            <li>
              <FormattedMessage
                id='cgau.list2.8'
                defaultMessage="CDs"
              />
            </li>
            <li>
              <FormattedMessage
                id='cgau.list2.9'
                defaultMessage="DVDs"
              />
            </li>
            <li>
              <FormattedMessage
                id='cgau.list2.10'
                defaultMessage="Jeux"
              />
            </li>
            <li>
              <FormattedMessage
                id='cgau.list2.11'
                defaultMessage="Livres sans code-barres"
              />
            </li>
          </ul>
        </p>
        <p>
          <FormattedMessage
            id='cgau.list3.1'
            defaultMessage="Par ailleurs, AMMAREAL peut aussi refuser l'achat des livres suivants"
          />
          <ul>
            <li>
              <FormattedMessage
                id='cgau.list3.2'
                defaultMessage="Les épreuves non-corrigées"
              />
            ;</li>
            <li>
              <FormattedMessage
                id='cgau.list3.3'
                defaultMessage="Les encyclopédies généralistes en plusieurs volumes comme les encyclopédies Larousse, Atlas, Universalis, Tout l’Univers"
              />   
            ;</li>
            <li>
              <FormattedMessage
                id='cgau.list3.4'
                defaultMessage="Les annales de conférence (« proceedings » en anglais)"
              />  
            ;</li>
            <li>
            <FormattedMessage
              id='cgau.list3.5'
              defaultMessage="Les livres à caractère pornographique, discriminatoire ou incitant à la haine (livre « Mein Kampf » d'Adolf Hitler)"
            />
            ;</li>
            <li>
            <FormattedMessage
              id='cgau.list3.6'
              defaultMessage="Les livres ayant un titre différent entre, d’une part le panier ou bordereau de reprise, et d’autre part le livre proposé à la vente par le particulier"
            />
            ;</li>
            <li>
              <FormattedMessage
                id='cgau.list3.7'
                defaultMessage="Les livres provenant des éditeurs ou des collections suivants"
              /> :
              <ul>
                <li>Reader's digest / Sélection du livre ;</li>
                <li>Famot ;</li>
                <li>Crémille ;</li>
                <li>Rombaldi ;</li>
                <li>Edito Service ;</li>
                <li>Rencontre ;</li>
                <li>Cercle du bibliophile ;</li>
                <li>François Beauval ;</li>
                <li>Club des femmes ;</li>
                <li>Nous Deux.</li>
              </ul>
            </li>

          </ul>
        </p>
        <p>
          <FormattedMessage
            id='cgau.art1.8'
            defaultMessage="Ces Conditions s'appliquent à l'exclusion de toutes autres conditions, notamment celles applicables aux transactions effectuées dans le cadre du service de vente en ligne. Les présentes Conditions Générales d’achat d’Articles pouvant faire l’objet de modifications, les conditions applicables à une opération d’achat donnée sont celles en vigueur sur l’Application au jour de la conclusion de la vente."
          /> 
        </p>
        <p className={styles.art}>
          <FormattedMessage
            id='cgau.art2.title'
            defaultMessage="Article 2- Capacité – Acceptation des Conditions Générales d’Achat"
          />
        </p>
        <p className={styles.bold}>
          <FormattedMessage
            id='cgau.art2.1.title'
            defaultMessage="2.1 Capacité – Utilisation du Site et de l’Application Mobile"
          />
        </p>
        <p>
          <FormattedMessage
            id='cgau.art2.1.1'
            defaultMessage="Le Service d’achat d’Articles d’occasion AMMAREAL (CIRCULAREAL) a pour fonction de vous assister dans le processus de reprise de vos Articles. Afin de pouvoir utiliser CIRCULAREAL, vous devez impérativement être capable juridiquement de contracter (c’est-à-dire pour tout ressortissant français, être majeur ou émancipé) et résider en France métropolitaine au jour de l’offre d’achat de vos Articles. Afin de justifier de votre âge, vous devez remplir les champs requis figurant dans la page « Mes données personnelles vendeur » sur votre compte personnel, et notamment"
          />
        </p>
         
          <ul>
            <li>
            <FormattedMessage
              id='cgau.art2.1.2'
              defaultMessage="Le type de document vous permettant d’attester votre identité : carte d’identité, passeport, permis de conduire, carte de séjour"
            />
            </li>
            <li>
            <FormattedMessage
              id='cgau.art2.1.3'
              defaultMessage="Le numéro du document choisi"
            />;
            </li>
            <li>
            <FormattedMessage
              id='cgau.art2.1.4'
              defaultMessage="Le nom de l’autorité ayant délivré le document"
            />;
            </li>
            <li>
            <FormattedMessage
              id='cgau.art2.1.5'
              defaultMessage="La date de délivrance du document"
            />; 
            </li>
            <li>
              <FormattedMessage
                id='cgau.art2.1.6'
                defaultMessage="Votre numéro de téléphone"
              /> ;
            </li>
            <li>
              <FormattedMessage
                id='cgau.art2.1.7'
                defaultMessage="Votre date de naissance."
              />.
            </li>
          </ul>
        <p>
          <FormattedMessage
            id='cgau.art2.1.8'
            defaultMessage="En cliquant sur la case « J’atteste sur l’honneur la véracité de ces informations » puis sur l’icône « Valider », vous certifiez être majeur au jour de l’offre de vente de vos Articles et vous certifiez la véracité des informations fournies à AMMAREAL."
          /> 
          <br></br><br></br>
          <FormattedMessage
            id='cgau.art2.1.9'
            defaultMessage="Sauf cas de fraude dont il vous appartient de rapporter la preuve, vous êtes responsable financièrement de vos démarches sur le Site et l’Application Mobile, notamment de l’utilisation qui sera faite de votre nom d’utilisateur et de votre mot de passe."
          />
          <br></br><br></br>
          <p className={styles.bold}>
          <FormattedMessage
            id='cgau.art2.1.10'
            defaultMessage="Une utilisation du Site et de l’Application Mobile qui serait frauduleuse, ou qui contreviendrait aux présentes Conditions Générales de reprise de livre d’occasion, justifiera que vous soit refusé, à tout moment, l’accès à CIRCULAREAL ou aux autres fonctionnalités du Site et de l’Application Mobile."
          /> 
          </p>
          <br></br>
          <FormattedMessage
            id='cgau.art2.1.11'
            defaultMessage="Conformément aux articles R.321-1 et suivants du Code pénal, vous autorisez AMMAREAL à inscrire sur son registre déclaré en préfecture, outre la description des Articles vendus à AMMAREAL, vos noms, prénoms, qualité et domicile ainsi que la nature, le numéro et la date de délivrance de la pièce d'identité produite avec l'indication de l'autorité qui l'a établie."
          /> 
        </p>
        <p className={styles.art}>
          <FormattedMessage
            id='cgau.art2.2.title'
            defaultMessage="2.2 Acceptation des Conditions Générales d’Achat"
          /> 
        </p>
        <p>
          <FormattedMessage
            id='cgau.art2.2.1'
            defaultMessage="Aucune offre d’achat ne peut être formulée par AMMAREAL sans acceptation des Conditions Générales d’Achat du Site et de l’Application Mobile. Vous pouvez prendre connaissance des Conditions Générales d’Achat à tout moment lors de votre navigation sur le Site et l’Application Mobile."
          />
          <br></br><br></br>
          <FormattedMessage
            id='cgau.art2.2.2'
            defaultMessage="Votre acceptation effective des présentes Conditions Générales d'Achat se fera lors de la confirmation de votre offre de reprise (article 3.5). Il vous sera demandé de reconnaître avoir pris connaissance de l’intégralité des présentes Conditions Générales d'Achat et de les accepter de manière irrévocable, entière et sans réserve en cliquant sur la case « J’accepte les CGA d’AMMAREAL». La confirmation de votre vente ne pourra se faire qu’après acceptation des présentes Conditions Générales d’Achat."
          />
          <br></br><br></br>
          <FormattedMessage
            id='cgau.art2.2.3'
            defaultMessage="Sauf preuve contraire, les données enregistrées par le Site et l’Application Mobile lors de la transaction, conformément aux dispositions de l’article 8 des présentes Conditions Générales d'Achat, constitueront la preuve de l’ensemble des opérations passées par AMMAREAL, et notamment de l’acceptation des Conditions Générales d'Achat."
          />
        </p>
        <p className={styles.art}>
          <FormattedMessage
            id='cgau.art3.title'
            defaultMessage="Article 3 – Offre d’achat AMMAREAL"
          />
        </p>
        <p>
        <FormattedMessage
          id='cgau.art3.1'
          defaultMessage="AMMAREAL s’engage à formuler une offre de reprise contre cagnottage sur la carte fidélité AMMAREAL (ci-après l’«Offre») de vos Articles dans le respect des conditions ci-dessous énoncées."
        />
          <br></br>
        </p>  
        <p className={styles.bold+ " " + styles.w100}>
          <FormattedMessage
            id='cgau.art3.sub1.title'
            defaultMessage="3.1 Accès au Site et à l’Application Mobile"
          />
        </p>
        <p>
          <FormattedMessage
            id='cgau.art3.sub1.1'
            defaultMessage="Le seul accès au Site et à l’Application Mobile est totalement gratuit."
          />
          <br></br><br></br>
        <p className={styles.bold+ " " + styles.w100}>
          <FormattedMessage
            id='cgau.art3.2.title'
            defaultMessage="3.2 Compte personnel"
          />
        </p>
        <FormattedMessage
            id='cgau.art3.2.1'
            defaultMessage="Afin de pouvoir vendre vos Articles sur le Site ou sur l’Application Mobile, vous devez vous connecter sur votre compte personnel CIRCULAREAL ou, si c’est votre première vente, créer un compte personnel CIRCULAREAL en fournissant diverses informations nécessaires au traitement de votre vente, et notamment votre adresse électronique et un mot de passe, et aux obligations légales."
          />

          <FormattedMessage
            id='cgau.art3.2.2'
            defaultMessage="L’ensemble des informations ainsi collectées est traité par AMMAREAL via CIRCULAREAL conformément aux dispositions de l’article 8 des présentes Conditions Générales d'Achat. 
            Vos données pourront être transmises à des sous-traitants."
          /> 
          <br></br><br></br>
          <p className={styles.bold+ " " + styles.w100}>
            <FormattedMessage
              id='cgau.art3.3.title'
              defaultMessage="3.3 Conditions relatives à vos Articles"
            /> 
          </p>
          
          <FormattedMessage
            id='cgau.art3.3.1'
            defaultMessage="Les articles proposés à l’achat doivent scrupuleusement correspondre à notre Charte de qualité mise à disposition présentée sur le Site et l’Application."
          />
          <br></br><br></br>
          <FormattedMessage
            id='cgau.art3.3.2'
            defaultMessage="Nous vous rappelons en particulier que"
          />
          </p>
          <ul>
            <li>
            <FormattedMessage
              id='cgau.art3.3.3'
              defaultMessage="Les articles proposés à l’achat doivent être en très bon état"
            />
            </li>
            <li>
            <FormattedMessage
              id='cgau.art3.3.4'
              defaultMessage="Les articles promotionnels impropres à la vente ne peuvent être repris"
            />;
            </li>
            <li>
            <FormattedMessage
              id='cgau.art3.3.5'
              defaultMessage="Les articles dépourvus de code-à-barre (notamment ISBN ou EAN) ne sont pas repris"
            />;
            </li>
            <li>
            <FormattedMessage
              id='cgau.art3.3.6'
              defaultMessage="Les articles n’étant pas des livres, au sens commun du terme, ne sont pas repris ; peuvent notamment être exclus de la reprise les magazines, les catalogues, les calendriers, les agendas, les CDs, les DVDs, et les jeux"
            />  
             ;</li>
            <li>
            <FormattedMessage
              id='cgau.art3.3.7'
              defaultMessage="Les épreuves non-corrigées, les encyclopédies généralistes, les annales de conférences, et certaines éditions ou collections ne sont pas reprises"
            />
            ;</li>
            <li>
            <FormattedMessage
              id='cgau.art3.3.8'
              defaultMessage="Vous ne pouvez pas proposer plusieurs exemplaires d’un même article que ce soit dans une même vente."
            /> 
            </li>
          </ul>
      
        <p>
          <br></br>
          <p className={styles.bold+ " " + styles.w100}>
            <FormattedMessage
              id='cgau.art3.4.title'
              defaultMessage="3.4 Estimation d’une cotation pour achat"
            />
          </p>
   
            <FormattedMessage
              id='cgau.art3.4.1'
              defaultMessage="Afin d’estimer le montant total de notre Offre, vous devez entrer le code barre (ISBN ou EAN) des Articles que vous désirez proposer à la vente. Seuls les Articles dont le code barre correspond à un produit référencé dans notre base de données pourront être inclus dans une Offre. Une fois vos Articles enregistrés, il vous faut cliquer sur « PANIER » afin que AMMAREAL vous indique le montant total de son Offre."
            />
          <br></br><br></br>
          <p className={styles.bold+ " " + styles.w100}>
            <FormattedMessage
              id='cgau.art3.5.title'
              defaultMessage="3.5 Confirmation de l’Offre d’achat"
            />
          </p>
            <FormattedMessage
              id='cgau.art3.5.1'
              defaultMessage="Pour accepter l’Offre de AMMAREAL au montant de la cotation indiquée, vous devez cliquer sur « TERMINER » ou « FINALISER ». L’offre est alors définitivement confirmée."
            />
          <br></br><br></br>
          <p className={styles.bold+ " " + styles.w100}>
            <FormattedMessage
              id='cgau.art3.6.title'
              defaultMessage="3.6 Expédition, inspection, non-conformité"
            />
          </p>
          <FormattedMessage
              id='cgau.art3.6.1'
              defaultMessage="Le bordereau d’expédition généré par le Site et par l’Application Mobile à la fin du processus de reprise ne peut être utilisé que pour l’envoi des articles proposés à l’achat à AMMAREAL. Le cout d’expédition des livres proposés à l’achat est à la charge d’AMMAREAL."
            />

          <br></br><br></br>
          <p className={styles.bold+ " " + styles.w100}>
            <FormattedMessage
              id='cgau.art3.6.sub1.title'
              defaultMessage="3.6.1 - Réalisation définitive du contrat"
            />
          </p>
         
            <FormattedMessage
              id='cgau.art3.6.sub1.1'
              defaultMessage="La réalisation de la vente est conditionnée par l’inspection de vos Articles proposés à l’achat par un opérateur AMMAREAL à réception du colis.
              La vente sera pleinement, entièrement et irrévocablement formée après l’inspection des livres par l’opérateur AMMAREAL, qui deviendra alors pleinement propriétaire de vos Articles."
            />
          <br></br><br></br>
          <p className={styles.bold+ " " + styles.w100}>
          3.6.2 {" "}
          </p>
            <FormattedMessage
              id='cgau.art3.6.2.1'
              defaultMessage="Vous acceptez que seuls le ou les Articles conformes à l’Offre et respectant la Charte de reprise CIRCULAREAL-AMMAREAL vous soient réglés par virement pour un prix global corrigé qui sera recalculé par AMMAREAL. Ce nouveau prix global, calculé pour l’ensemble des Articles conformes, sera égal à la somme des valorisations unitaires déterminées au moment de l’acceptation par le client de l’Offre d’origine pour les Articles concernés. Si les Conditions sont réunies, AMMAREAL préparera un virement pour la somme qui vous est due, la somme virée étant fonction des livres que vous avez proposés à l’achat à AMMAREAL et effectivement repris par AMMAREAL suite à leur inspection."
            />
            <br></br><br></br>

          <p className={styles.bold+ " " + styles.w100}>
            3.6.3 {" "}
          </p>
            <FormattedMessage
              id='cgau.art3.6.3.1'
              defaultMessage="Vos Articles jugés comme étant défectueux ou non conformes à l’Offre ou à la Charte CIRCULAREAL-AMMAREAL seront conservés par AMMAREAL pendant une durée de 10 jours à partir de la validation définitive de votre vente par l’opérateur AMMAREAL. Dans ce temps, vous avez la possibilité d’en demander le retour contre une étiquette fournie par vos soins et du transporteur de votre choix. Au-delà de 10 jours, vos Articles non repris seront mis au recyclage par AMMAREAL."
            />
          </p>
          <p className={styles.art}>
            <FormattedMessage
              id='cgau.art4.title'
              defaultMessage="Article 4 – Prix"
            />

          </p>
          <p>
            <FormattedMessage
              id='cgau.art4.1'
              defaultMessage="Le prix versé par AMMAREAL est le prix total proposé dans l’Offre CIRCULAREAL en fonction de la cotation réalisée initialement ou le prix recalculé par AMMAREAL en cas d’Articles jugés comme étant défectueux ou non conformes à l’Offre ou à la Charte de qualité CIRCULAREAL-AMMAREAL. Tous les achats, quelle que soit leur provenance, seront payés par virement bancaire en EUROS."
            />
            <br></br><br></br>
            <FormattedMessage
              id='cgau.art4.2'
              defaultMessage=" AMMAREAL précise que les prix proposés pour le rachat d’Articles sont valables uniquement au moment de la cotation. Les prix d’achat des Articles peuvent évoluer dans le temps. 
              En utilisant le CIRCULAREAL, vous reconnaissez agir comme dans un but privé, et ne pas être un vendeur professionnel. A ce titre, le rachat des Articles par AMMAREAL ne donnera pas lieu au paiement de la TVA."
            />
          </p>
         
            <p className={styles.art}>
              <FormattedMessage
                id='cgau.art5.title'
                defaultMessage="Article 5 – Paiement"
              />
            </p>
            <p>
              <p className={styles.bold+ " " + styles.w100}>
                <FormattedMessage
                  id='cgau.art5.1.title'
                  defaultMessage="5.1 Moment du paiement - "
                />
              </p>
                <FormattedMessage
                  id='cgau.art5.1.1'
                  defaultMessage="Le paiement par virement de vos Articles par AMMAREAL n’est effectué qu’une fois le contrat définitivement formé (voir article 3.6.1). "
                /> 
              <br></br>
              <br></br>
            
              <p className={styles.bold+ " " + styles.w100}>
                <FormattedMessage
                  id='cgau.art5.2.title'
                  defaultMessage="5.2 Modes & délais de paiement"
                />
              </p>
                <FormattedMessage
                  id='cgau.art5.2.1'
                  defaultMessage="Le paiement pourra se faire exclusivement par virement bancaire sur la base de l’IBAN et du BIC basé en France que vous avez renseigné sur le Site ou dans l’Application Mobile, le mois suivant la réception et l’inspection des livres expédiés."
                />
            </p>
         
            <p className={styles.art}>
              <FormattedMessage
                id='cgau.art6.title'
                defaultMessage="Article 6 – Responsabilité"
              />
            </p>
            <p>
        
            <FormattedMessage
              id='cgau.art6.1'
              defaultMessage="Les services proposés sont conformes à la législation française en vigueur."
            />  
            <br></br><br></br>

            <FormattedMessage
              id='cgau.art6.2'
              defaultMessage="AMMAREAL ne saurait être tenu pour responsable de l’inexécution du contrat conclu au cas où l'inexécution de ses obligations serait imputable soit au fait imprévisible et insurmontable d'un tiers au contrat, soit à un cas de force majeure ou d'un cas fortuit, de votre fait ou du fait d’un tiers ou de causes extérieures telles que les conflits sociaux, intervention des autorités civiles ou militaires, catastrophes naturelles, incendies, dégâts des eaux."
            />
            <br></br><br></br>

            <FormattedMessage
              id='cgau.art6.3'
              defaultMessage="De même, la responsabilité de AMMAREAL ne saurait être engagée pour tous les inconvénients ou dommages inhérents à l'utilisation du réseau Internet, notamment une rupture de service, interruption du réseau de télécommunications ou du réseau électrique dont elle ne serait pas à l’origine."
            /> 
            <br></br><br></br>

            <FormattedMessage
              id='cgau.art6.4'
              defaultMessage="Tout Article identifié comme volé ou perdu sera remis aux autorités compétentes sans que la responsabilité de AMMAREAL puisse être recherchée."
            /> 
            <br></br><br></br>

            <FormattedMessage
              id='cgau.art6.5'
              defaultMessage="La responsabilité de AMMAREAL ne pourra également pas être engagée en cas d’usage frauduleux de votre identifiant ou de votre mot de passe."
            />
          </p>
          <p className={styles.art}>
            <FormattedMessage
              id='cgau.art7.title'
              defaultMessage="Article 7 – Traitement des données à caractère personnel"
            />
          </p>
          <p>
            <FormattedMessage
              id='cgau.art7.1'
              defaultMessage="AMMAREAL responsable de traitement, s’engage à traiter vos données personnelles avec tout le soin requis et exclusivement aux fins légitimes pour lesquelles elles ont été recueillies, conformément à toutes les dispositions légales en vigueur."
            />
          <br></br><br></br>

            <FormattedMessage
              id='cgau.art7.2'
              defaultMessage="Les informations que vous communiquez sur le Site ou l’Application Mobile permettent à AMMAREAL de créer, gérer votre compte, répondre à des obligations légales, gérer vos commandes et leur suivi, mesurer votre satisfaction et personnaliser les services de AMMAREAL. Elles sont aussi utilisées pour établir des analyses statistiques et lutter contre la fraude. Conformément à l'article 32 de la Loi Informatique et Libertés du 6 janvier 1978 modifiée par la loi 2004-801 du 6 août 2004, les informations indispensables à AMMAREAL pour traiter et exécuter les commandes sont signalées par un astérisque dans les pages de l’application CIRCULAREAL."
            />
          <br></br><br></br>

            <FormattedMessage
              id='cgau.art7.3'
              defaultMessage="Les autres informations demandées, pour lesquelles la réponse est facultative ou les informations relatives à votre intérêt pour les offres susceptibles de vous être adressées par AMMAREAL, sont destinées à mieux connaître les utilisateurs et ainsi à améliorer les services qui leur sont proposés, tout en en assurant la sécurité. Sauf opposition de votre part, AMMAREAL peut être amené à communiquer ces informations facultatives vous concernant à des sociétés partenaires."
            />
          <br></br><br></br>

            <FormattedMessage
              id='cgau.art7.4'
              defaultMessage="La durée de conservation des données est de 10 années afin répondre à des obligations légales."
            />
          <br></br><br></br>

            <FormattedMessage
              id='cgau.art7.5'
              defaultMessage="AMMAREAL s'engage dans ce cadre à se conformer aux législations françaises et européennes sur la protection des données à caractère personnel, et à assurer un niveau de protection adéquat aux traitements opérés."
            />
          <br></br><br></br>

            <FormattedMessage
              id='cgau.art7.6'
              defaultMessage="Conformément à la loi Informatique et Libertés du 06/01/1978 modifiée et au Règlement (UE) 2016/679, vous disposez d'un droit d'accès, de rectification et de suppression des données personnelles vous concernant et d’opposition à leur traitement. Vous bénéficiez également d’un droit à la limitation du traitement et d’un droit à la portabilité de vos données. Pour exercer vos droits, il suffit de nous transmettre votre demande via"
            />
            <a rel="noopener noreferrer" target='_blank' href='https://www.ammareal.fr/nous-contacter'>
              <FormattedMessage
                id='cgau.art7.contactform'
                defaultMessage="le formulaire de contact"
              />
            </a>
          <br></br><br></br>

            <FormattedMessage
              id='cgau.art7.7'
              defaultMessage="Pour en savoir plus sur les modalités de traitement de vos données (base légale et durées de conservation) et d’exercice de vos droits, nous vous invitons à consulter notre"
            />
          <a  rel="noopener noreferrer" target='_blank' href='https://www.ammareal.fr/content/14-conditions-generales-de-vente'> 
            <FormattedMessage
              id='cgau.art7.dataProtectionPolicy'
              defaultMessage="Politique de protection des données"
            />
            </a>

          </p>
          <p className={styles.art}>
            <FormattedMessage
              id='cgau.art8.title'
              defaultMessage="Article 8 – Propriété intellectuelle"
            />
          </p>
        <p>

          <FormattedMessage
            id='cgau.art8.1'
            defaultMessage="Le Site, son contenu et tous les éléments le constituant, sont des créations pour lesquelles AMMAREAL et/ou, le cas échéant, des Partenaires, sont titulaires de l'intégralité des droits de propriété intellectuelle et/ou des droits d'exploitation, en particulier au titre du droit d'auteur, du droit des bases de données, du droit des marques, et du droit des dessins et modèles."
          />
          <br></br><br></br>

          <FormattedMessage
            id='cgau.art8.2'
            defaultMessage="Le Site, ainsi que les logiciels, bases de données, textes, informations, analyses, images, photographies, graphismes, logos, sons ou toutes autres données contenues sur le Site demeurent la propriété exclusive d’AMMAREAL et/ou des Partenaires ou, le cas échéant, de leurs propriétaires respectifs avec qui ces derniers ont passé des accords d'utilisation."
          />
          <br></br><br></br>

          <FormattedMessage
            id='cgau.art8.3'
            defaultMessage="Il vous est concédé un droit d'utilisation non-exclusif, non transférable et dans un cadre privé, du Site et des données contenues sur le Site. Le droit ainsi concédé consiste en un droit de consulter en ligne les données et informations contenues sur le Site. Ce droit d'utilisation s'entend uniquement d’un usage strictement privé."
          />
          <br></br><br></br>

          <FormattedMessage
            id='cgau.art8.4'
            defaultMessage="Toute autre utilisation du Site, notamment commerciale, de votre part est interdite, et est constitutive de contrefaçon, sanctionnée en tant que telle par le Code de la Propriété intellectuelle. Vous vous interdisez notamment, de manière non exhaustive, de reproduire et/ou représenter pour un usage autre que privé, vendre, distribuer, émettre, traduire, adapter, diffuser et communiquer intégralement ou partiellement sous quelque forme que ce soit tout élément, information ou donnée du Site. "
          />
          <br></br><br></br>

          <FormattedMessage
            id='cgau.art8.5'
            defaultMessage="En outre, vous vous interdisez d'introduire, par quelque moyen que ce soit, des données susceptibles de modifier ou porter atteinte au contenu ou la présentation du Site."
          />
          <br></br><br></br>
          <FormattedMessage
            id='cgau.art8.6'
            defaultMessage="Tout lien hypertexte avec le Site, quel que soit le type de lien, doit faire l'objet d'une autorisation préalable de AMMAREAL, agissant au nom et pour le compte du titulaire du droit concerné, sur support papier ou électronique."
          />
        </p>
        <p className={styles.art}>
          <FormattedMessage
            id='cgau.art9.title'
            defaultMessage="Article 9 – Litige - Droit Applicable"
          />
        </p>
        {/* <p>
          <FormattedMessage
            id='cgau.art9.1'
            defaultMessage="Les présentes Conditions Générales d’Achat contre cagnottage carte de fidélité sont soumises à la loi française."
          />
        </p> */}
        <div>
          <FormattedMessage
            id='cgau.art9.1a'
            defaultMessage="Conformément aux dispositions du Code de la consommation concernant le règlement amiable des litiges, SAS Ammareal adhère au Service du Médiateur du e-commerce de la FEVAD (Fédération du e-commerce et de la vente à distance) dont les coordonnées sont les suivantes"
          />
           : 
          <br></br>
            Médiateur de la consommation FEVAD
            BP 20015 - 75362 PARIS CEDEX 8 - <a href='https://www.mediateurfevad.fr' target='_blank' rel="noopener noreferrer">https://www.mediateurfevad.fr</a>.
            
          <FormattedMessage
            id='cgau.art9.1b'
            defaultMessage="Après démarche préalable écrite des consommateurs vis-à-vis de SAS Ammareal, le Service du Médiateur peut être saisi pour tout litige de consommation dont le règlement n’aurait pas abouti. 
            Pour connaître les modalités de saisine du Médiateur, cliquer"
          />
          {' '}<a href="https://www.mediateurfevad.fr/index.php/espace-consommateur-2/#msdynttrid=aBApxVZOiuCNmdkmh89TpzWQcRAv8I1S8uR3TBkVHTA" target='_blank' rel="noopener noreferrer">
            <FormattedMessage
            id='here'
            defaultMessage="ici"
          />
          </a>.
        </div>
        <p className={styles.art}>
          <FormattedMessage
            id='cgau.art10.title'
            defaultMessage="Article 10 - Service relation client – Contacts divers – Coordonnées."
          />
        </p>
        <p>
          <FormattedMessage
            id='cgau.art10.1'
            defaultMessage="Conformément à l’article L.121-18 du Code de la Consommation, vous trouverez ci-dessous l’ensemble des informations vous permettant de contacter effectivement les services de AMMAREAL."
          />

          <FormattedMessage
            id='cgau.art10.2'
            defaultMessage="Pour toute information ou question ou suggestion, vous pouvez contacter"
          />
          <a  rel="noopener noreferrer" target='_blank' href='https://www.ammareal.fr/nous-contacter'> 
            <FormattedMessage
              id='cgau.art10.clientService'
              defaultMessage="le service relation client"
            />
          </a>
          <br></br><br></br>

          <FormattedMessage
            id='cgau.art10.legalMentions'
            defaultMessage="Mentions légales : AMMAREAL, Société par Actions Simplifiée au capital de 25 000 euros, dont le siège social sis à ATHIS-MONS, 31 rue Marcelle Henry, immatriculée au Registre du Commerce et des Sociétés de EVRY sous le numéro 797 906 906,"
          />
          <br></br><br></br>

          <FormattedMessage
            id='cgau.art10.host'
            defaultMessage="Hébergeur du site CIRCULAREAL"
          />
          <FormattedMessage
            id='cgau.art10.hostAdr'
            defaultMessage="OVH Siège social : 2 rue Kellermann – 59100 Roubaix – France"
          />
        </p>
        <p className={styles.art}>
          <FormattedMessage
            id='cgau.art11.title'
            defaultMessage="Article 11 - Dispositions finales"
          />
        </p>
        <p>
          <FormattedMessage
            id='cgau.art11.1'
            defaultMessage="Le fait qu’AMMAREAL ne se prévale pas, à un moment donné, d'une des dispositions des Conditions Générales d’Achat et d’Utilisation ne pourra être interprété comme valant renonciation par elle-même à se prévaloir ultérieurement de l'une de ces dispositions."
          />
          <br></br><br></br>
          <FormattedMessage
            id='cgau.art11.2'
            defaultMessage="Dans le cas où l'une des dispositions des Conditions Générales serait déclarée nulle ou sans effet, cette disposition serait réputée non écrite, sans que cela n'affecte la validité des autres dispositions, sauf si la disposition déclarée nulle ou sans effet était essentielle et déterminante."
          />
          <br></br><br></br>
        
        </p>
            </div>
        </div>
         
        :null}
    </>
  );
}
