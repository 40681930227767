import React from 'react';
import styles from './BookChoice.module.css'
//store
import { BookContext } from '../../store/BookContext'
import { LanguageContext } from '../../store/LanguageContext';
//utils
import { fetchEventsUpdate } from '../../utils/events';
//Trad
import { FormattedMessage } from 'react-intl';
import { GetTranslateText } from '../../utils/translate';

function BookChoice() {
    const { bookState, bookDispatch } = React.useContext(BookContext);
    const [isExistBook, setIsExistBook] = React.useState(false)
    const { languageState} = React.useContext(LanguageContext);
    const [error, setError]= React.useState(null)
    var pricePartner = (Math.round(bookState.book.price * 0.2 * 100)/100).toFixed(2);
    
    React.useEffect(()=>{
        if (bookState.book && bookState.cart.filter(e=>e.userChoice==='sold').some(e => e.isbn13 === bookState.book.isbn13 )) { 
            console.log('Is exist')
            setIsExistBook(true)
        }
    },[bookState.book, bookState.cart])
function makeChoice(choice){
    fetchEventsUpdate(choice, bookState)
    .then(res=>{
        if(!res.error){
            bookDispatch({type:choice==="kept"?'keepBook':"sellBook"})
        }else{
            setError(res)
        }
    })

}
  return (
        <div className={styles.bookChoiceContent}>
            <div className="ph10">
                <div className={styles.bookRow}>
                    <img 
                        className={styles.imageResponsive}
                        src={bookState.book.image} 
                        alt={bookState.book.title}/>
                    <div className={styles.bookInfo}>
                        <h6 className={styles.bookTitle}>{bookState.book.title}</h6>
                        {bookState.book.authors ?
                            <p className={styles.authors}><span>de </span>
                                {bookState.book.authors.map((author, index) => {
                                    return index + 1 < bookState.book.authors.length ?
                                        <span key={index}>{author.split(', ')[1]} {author.split(', ')[0]} et </span>
                                    :
                                        <span key={index}>{author.split(', ')[1]} {author.split(', ')[0]}</span>
                                })}
                            </p>
                        :null}
                    </div>
                </div>
                      
                <div className={styles.sentence}>
        
                {bookState.book.status === 'accepted' && !isExistBook ?
                    <p className={styles.infoPrice}>
                     <FormattedMessage 
                      id="bookChoice.gain"
                      defaultMessage="vous rapporterait "
                    /> 
                     <span className={styles.priceCustomer}> {bookState.book.price.toString().replace('.', ',')} <sup>€</sup></span>.
                    <br></br>
                    {GetTranslateText(languageState,'checkoutPage.giftPartner1')}
                    
                      <span className={styles.pricePartner}> {pricePartner.toString().replace('.', ',')} <sup>€ </sup></span>
                      {GetTranslateText(languageState,'checkoutPage.giftPartner2')} 
                    </p>
                 :
                 bookState.book.status === 'accepted' && isExistBook ?
                    <div className={"d-flex flex-column "}>
                        <p  className={"text-center"}>
                        <FormattedMessage 
                            id="bookChoice.AlertDuplicate.1"
                            defaultMessage="Ce livre est déjà dans votre panier."
                        />
                        </p>
                        <p>
                            <FormattedMessage 
                                id="bookChoice.AlertDuplicate.2"
                                defaultMessage="D'après l'article 3.3 de nos CGAU, vous ne pouvez pas proposer plusieurs exemplaires d’un même article dans une même vente."
                            />
                        </p>
                    </div>

                 :
                    <p className={styles.infoPrice}>
                        <FormattedMessage 
                            id="bookChoice.sorry.1"
                            defaultMessage="Désolé, ce livre n’est actuellement"
                        />
                       {" "}
                        <FormattedMessage 
                            id="bookChoice.sorry.2"
                            defaultMessage="pas repris."
                        />
                    </p>
                }
               
                </div>
            </div> 
                <div className={styles.buttonRow}>
                {bookState.book.status === 'accepted' && !isExistBook ?
                <>
                    <button 
                        className={'btn btn-grey-dark'}
                        onClick={ () => {makeChoice("kept") }}
                    ><i className="far fa-times-circle"></i><FormattedMessage id='bookChoice.btnKeep'/></button>
                
                    <button 
                        className={'btn btn-orange shadow'}
                        onClick={ () => { makeChoice("sold")  }}
                    ><i className="far fa-check-circle"></i> <FormattedMessage id='bookChoice.btnSell'/></button>
                </>
                :
                    <button 
                        className={'btn btn-grey-dark'}
                        onClick={ () => {makeChoice("kept") }}
                    ><i className="far fa-times-circle"></i>  {GetTranslateText(languageState,'bookChoice.btnKeep')}</button>
                }  
                    
                </div>
                {
                    error?
                    <p className={styles.error}>
                        {error.message}
                    </p>
                    :
                     null
                }
            
        </div>
  );
}

export default BookChoice;
