import React from 'react';
import { NavigationContext } from '../../store/NavigationContext'
import { CustomerContext } from '../../store/CustomerContext'

import moment from 'moment-timezone';

import "react-datepicker/dist/react-datepicker.css";
import styles from './InfoForm.module.css';

// import {isMobile} from 'react-device-detect';
import { FormattedMessage } from 'react-intl';

export default function InfoForm() {
    moment.tz.setDefault("Europe/Paris");
    const { customerDispatch } = React.useContext(CustomerContext);
    const { navigationDispatch } = React.useContext(NavigationContext);
    
    const [ error, setError ] = React.useState(null);
    const [ query, setQuery ] = React.useState('');

    const [ customer, setCustomer ] = React.useState({
        email:"",
        firstname: "",
        lastname:"",
        passwd:"",
        birthday:null,
        optin:false,
        optin_newsletter: false,
    });

    // const checkPwd = (pwd)=>{
    //     var bool = false
    //     var regex = /^[\w]{8,}$/
    //     var regexNot= /^(.{0,7}|[^0-9]*|[^A-Z]*)$/
    //     var regexSpec = /[^\w]/
    //     // console.log(pwd, regex.test(pwd),!regexNot.test(pwd));
    //     if(regex.test(pwd) && !regexNot.test(pwd)){
    //         setError(null)
    //         bool = true
    //     }else if(regexSpec.test(pwd)){
    //         setError("Votre mot de passe ne doit pas contenir de caractères spéciaux.")
    //     }else{
    //         setError("Votre mot de passe doit contenir au moins 8 caractères dont un chiffre et une majuscule.")
    //     }
    //     return bool
    // }
    
    React.useEffect(() => {
        const SaveCustomerInfos = () =>{
            if(query.birthday){
                fetch(`${process.env.REACT_APP_ADMIN_URL}/apiFront/customer/registration/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`
                    },
                    body: JSON.stringify( query )
                })
                .then(res => 
                    res.json()
                )  
                .then(res => {
                    // console.log(res, res.message)
                    if (!res || res.error) {
                       console.log("error", res)
                       if(res.message){
                           if(typeof res.message === 'string'){
                            setError(res.message)
                           }else if(typeof res.message === 'object'){
                            setError(res.message._cdata)
                           }
                           else{
                            setError('Erreur lors de la sauvegarde de vos informations')
                           }
                       }
                    }else{
                        // customerDispatch({type:'savedCustomer', payload: res.data})
                        // navigationDispatch({type:'customerInfo'})
                        customerDispatch({type:'loggedCustomer', payload: res.data})
                        navigationDispatch({type:'customerLoggedIn'})
                    }
                }).catch(function(err) {
                    console.log(err);
                });
            }else{
                setError("Date d'anniversaire obligatoire")
            }
        };
        if (query !== '') {
        // if (query !== '' && checkPwd(query.passwd)) {
            SaveCustomerInfos();
            setQuery('')
        }
    }, [query, navigationDispatch, customerDispatch, error])


const desktopDate = (date) =>{

    var dateFormat= moment(date).format("DD/MM/YYYY")
    var dateTest = moment(dateFormat, 'DD/MM/YYYY', true).isValid()
    // console.log("date",dateTest, dateFormat, date)

    if(dateTest){
        setCustomer({...customer, birthday:dateFormat})
    }else{
        setCustomer({...customer, birthday:null})
    }
}
return (
    <div className={styles.form}>
      <form className={styles.infoForm}
            id='form-step1'
            onSubmit={e => {
                e.preventDefault();
                setQuery(customer);       
        }}>
       {/* <h3 className={styles.title}>Mes infos</h3> */}
            <div className="form-group row noMarginHorizontal mt-5">
                <label className="col-4 col-form-label noPadding">
                <FormattedMessage 
                    id="accountCreationForm.firstname"
                    defaultMessage="Prénom"
                />
                </label>
                <div className="col-8">
                    <input type="text" className={"form-control " + styles.input}
                        onChange={e => setCustomer({...customer, firstname: e.target.value}) }
                        value={customer.firstname || ''}
                        required
                        />                
                </div>
            </div>
            <div className="form-group row noMarginHorizontal">
                <label className="col-4 col-form-label noPadding">
                <FormattedMessage 
                    id="accountCreationForm.lastname"
                    defaultMessage="Nom"
                />
                </label>
                <div className="col-8">
                    <input type="text" className={"form-control " + styles.input}
                        onChange={e => setCustomer({...customer, lastname: e.target.value}) }
                        value={customer.lastname || ''}
                        required
                        />               
                </div>
            </div>
            <div className="form-group row noMarginHorizontal">
                <label className="col-4 col-form-label noPadding">
                <FormattedMessage 
                    id="accountCreationForm.email"
                    defaultMessage="email"
                /> 
                </label>
                <div className="col-8">
                    <input type="email" className={"form-control " + styles.input}
                        onChange={e => setCustomer({...customer, email: e.target.value}) }
                        value={customer.email || ''}
                        required
                        />                
                </div>
            </div>
            <div className="form-group row noMarginHorizontal">
                <label className="col-4 col-form-label noPadding">
                <FormattedMessage 
                    id="accountCreationForm.pwd"
                    defaultMessage="Mot de passe"
                /> 
                </label>
                <div className="col-8">
                    <input type="password" className={"form-control " + styles.input}
                        onChange={e => {setCustomer({...customer, passwd: e.target.value}) }}
                        value={customer.passwd || ''}
                        required
                        minLength="5"
                        />                
                </div>
            </div>
            <div className="form-group row noMarginHorizontal">
                <label className="col-4 col-form-label noPadding">
                <FormattedMessage 
                    id="accountCreationForm.birthdate"
                    defaultMessage="Date de naissance"
                /> 
                </label>
                <div className="col-8">        
                    <input   className={"form-control " + styles.input}
                        placeholder='exemple: 01/12/1988'
                        onChange={e=>desktopDate(e.target.value)}
                        required
                        type="date"
                        // pattern="[0-3^a-b]{1}[0-9]{1}\/[0-1]{1}[0-9]{1}\/[0-9^a-b]{4}"
                    />
                </div>
            </div>
            
            {/* <div className={ "form-group " + styles.checkboxCustom}>
                <div className="custom-control custom-checkbox mt-3 ">
                    <input name="optin" type="checkbox" checked={customer.optin}  id="optin" className="custom-control-input"
                        onChange={(e)=>setCustomer({...customer, optin :e.target.checked})}
                    />
                    <label className="custom-control-label" htmlFor="optin">
                    <FormattedMessage 
                        id="accountCreationForm.optin.1"
                        defaultMessage="Recevoir les offres de nos partenaires"
                    /> 
                    </label>
                </div>
            </div> */}

            <div className={"form-group " + styles.checkboxCustom}>
                <div className="custom-control custom-checkbox mt-3 newsletter">
                    <input name="newsletter" type="checkbox" checked={customer.optin_newsletter} id="newsletter" className="custom-control-input"
                        onChange={(e)=>setCustomer({...customer, optin_newsletter :e.target.checked})}
                    
                    />
                    <label className="custom-control-label" htmlFor="newsletter">
                    <FormattedMessage 
                        id="accountCreationForm.optin.2"
                        defaultMessage="Recevoir notre newsletter"
                    />
                    </label>
                </div>
                <div className="invalid-feedback js-invalid-feedback-browser"></div>
            </div>
        </form>

        
        {error?
            <span className={"alert alert-danger " + styles.error} role="alert">
                {error}
            </span>
        :null}
     <div className={styles.buttonRow}>
        <div className={styles.buttonDiv}>
        <button 
            className={'btn btn-grey-dark'}
            onClick={ () => navigationDispatch({type:'displayLogin'})}
        ><i className="far fa-arrow-alt-circle-left"></i>
           <FormattedMessage 
                id="btn.backMin"
                defaultMessage='Retour'
            />
        </button>
        </div>
        
        <div className={styles.buttonDiv}>
        <button 
            className={'btn btn-orange shadow'}
            form='form-step1'
            type='submit'
        >
             <FormattedMessage 
                id="btn.inscription"
                defaultMessage="S'INSCRIRE"
              />
            <i className="far fa-arrow-alt-circle-right"></i>
        </button>
        </div>

    </div>
    
    </div>
  )
}
