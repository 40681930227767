import React from 'react';
import styles from './BackHeader.module.css';
import { BookContext } from '../../../store/BookContext'
import { NavigationContext } from '../../../store/NavigationContext'
import { CustomerContext } from '../../../store/CustomerContext'
import { LanguageContext } from '../../../store/LanguageContext';
import FeatherIcon from 'feather-icons-react';
import keyBoardIcon from '../../../assets/images/saisie-manuelle.svg'
import scannerIcon from '../../../assets/images/code-barre.svg'
import { FormattedMessage } from 'react-intl';
import { GetTranslateText } from '../../../utils/translate';

export default function BackHeader() {
  const { bookState } = React.useContext(BookContext)
  const { languageState } = React.useContext(LanguageContext)
  const { customerState } = React.useContext(CustomerContext)
  const { navigationState, navigationDispatch } = React.useContext(NavigationContext);
  let filteredCart = bookState.cart.filter(book => book.userChoice === 'sold')
//   const totalPrice = filteredCart.reduce((count, curItem) => count + curItem.price, 0)

  return (
    <nav className={styles.nav}>
        <div 
            className={styles.backButtonContainer}
            onClick={ () => {
                if(navigationState.displayCGAU){
                    navigationDispatch({ type:'cgauReturn' });
                }else{
                    navigationDispatch({ type:'back' });
                }
            }
            }>
                {/* <i className="fa arrow-left-circle"></i> */}
                <FeatherIcon className={styles.leftarrow} icon='arrow-left-circle' size="20"></FeatherIcon>
                <span className={styles.returnBtn}>
                <FormattedMessage 
                    id="btn.back"
                    defaultMessage="RETOUR"
                />
                </span>
        </div>
        {!navigationState.displayCGAU && !navigationState.displayRefurbCondition?
            <div className={styles.content}>
                {navigationState.displayWebcam?
                <img src={scannerIcon} alt='scannerIcon'></img>
                    :
                <img src={keyBoardIcon} alt='keyboard'></img>
                }
                <h2>{!navigationState.displayWebcam?GetTranslateText(languageState,"HeaderScan.typeScanManual"):navigationState.displayWebcam?GetTranslateText(languageState,'HeaderScan.typeScanAuto'):null}</h2>
                <div className={styles.shoppingBlock}
                    onClick={ () => {
                                    if(customerState.savedCustomer){
                                        navigationDispatch({type:'customerInfo'})
                                    }else{
                                        navigationDispatch({type:'displayCart'})
                                    }
                                }
                            }
                >
                    {/* <i className="fas fa-shopping-bag fa-2x"></i> */}
                    <span className={"badge badge-primary " + styles.badge}>{filteredCart.length}</span>

                    <div className={styles.shoppingIcon} style={{width:40,height:40,borderRadius:50,backgroundColor:"#114970", display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <FeatherIcon icon="shopping-bag" color="white"></FeatherIcon>
                    </div>
                    <div className={styles.shoppingLabel} >
                        <FormattedMessage
                            id='HeaderScan.title'
                            defaultMessage="Panier"
                        />
                    </div>
                </div>  
            </div>
        :
            <div className={styles.content}>
                {/* <i className="fas fa-barcode fa-3x"></i> */}
                <h5 style={{textAlign:'center', marginTop:10}}>
                    {navigationState.displayCGAU?
                    <FormattedMessage
                        id='cgau.titleCgau'
                        defaultMessage="Conditions Générales d’Utilisation et d’Achat d’Occasion"
                    />
                    :
                    <FormattedMessage 
                        id="RefurbConditionScreen.refurbCondition"
                        defaultMessage="Nos conditions de rachat"
                    />
                    }
                </h5>
                <div>
                    {/* <i className="fas fa-shopping-bag fa-2x"></i> */}
                </div>  
            </div>
        }
    </nav>
  );
}
